import { PaperAirplaneIcon } from "@heroicons/react/outline";
import { useGetShipmentNumberQuery } from "../../services/shipmentApi";
import { LoadingIcon } from "../custom/LoadingIcon";

export const ShipmentCount = ({ startDate, endDate }) => {
  const {
    data: shipmentsCount,
    isLoading: isLoadingShipmentsCount,
    isSuccess: isSuccessShipmentsCount,
    isError: isErrorShipmentsCount,
    error: errorShipmentsCount,
  } = useGetShipmentNumberQuery({ startDate, endDate });

  return (
    <div className="relative overflow-hidden rounded-lg bg-white px-4 pb-5 pt-5 shadow sm:px-6 sm:pt-6">
      <dt>
        <div className="absolute rounded-md bg-green-600 p-3 bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm  text-center ">
          <PaperAirplaneIcon
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">
          Nr. Spedizioni
        </p>
      </dt>
      <dd className="ml-16 flex items-baseline ">
        <p className="text-2xl font-semibold text-gray-900">
          {" "}
          {isLoadingShipmentsCount ? (
            <LoadingIcon color={"text-gray-900"} />
          ) : isSuccessShipmentsCount ? (
            <span>{shipmentsCount}</span>
          ) : isErrorShipmentsCount ? (
            <p>Errore: {errorShipmentsCount}</p>
          ) : (
            <>Si è verificato un errore nel server</>
          )}
        </p>
      </dd>
    </div>
  );
};
