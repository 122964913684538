import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PricingPage from "../pages/PricingPage";
import { useCheckRefreshTokenQuery } from "../services/authApi";
import { LoadingIcon } from "../components/custom/LoadingIcon";
import { setAuth } from "../features/auth/authSlice";
import { getToken, setTokenSecure } from "../functions/get_token";
import { LoginPage } from "../pages/LoginPage";
import LoaderSpinnerMain from "../components/spinner/LoaderSpinnerMain";
import { useEffect } from "react";
const RequireAuth = ({ allowedRoles }) => {
  const location = useLocation();
  var auth = useSelector((state) => state.auth);
  const acctk = getToken("token");

  const dispatch = useDispatch();
  const { data, isLoading, isFetching, isUninitialized, isSuccess } =
    useCheckRefreshTokenQuery(undefined, {
      //skip: acctk == "" ? false : true,
      refetchOnMountOrArgChange: true,
    });

  if (isLoading) {
    /*  */ return (
      <LoaderSpinnerMain
        isLoading={isLoading}
        size={140}
        topColor="#96bf3c"
        bottomColor="#96bf3c"
      />
    ); //<LoadingIcon color={"text-gray-900"} />;
  } else if (isSuccess) {
    auth = data;
    dispatch(setAuth(data));
    setTokenSecure("token", data.accessToken);
  }

  if (auth?.roles?.find((role) => allowedRoles?.includes(role))) {
    if (
      auth.subscriptionPrice == null &&
      auth.canSeeCatalog == false &&
      (auth?.roles?.includes(2) || auth?.roles?.includes(3))
    ) {
      return <PricingPage />;
    } else {
      return <Outlet />;
    }
  } else {
    if ((auth?.accessToken ?? "") == "") {
      return <LoginPage />;
      //<Navigate to="/login" replace state={{ from: location }} />;
    } else {
      <Navigate to={`/`} replace state={{ from: location }} />;
    }
  }
};

export default RequireAuth;
