export class MyDateTime {
  constructor() {}

  static now() {
    var date = new Date();
    return `${date.toLocaleDateString()} ${date
      .toLocaleTimeString()
      .substring(0, 5)}`;
  }

  static stringToDate = (dateString) => {
    //yyyy-mm-dd
    return new Date(
      dateString.split("-")[0], // yyyy
      dateString.split("-")[1], // mm
      dateString.split("-")[2] // dd
    );
  };

  static dateToString = (date) => {
    if (date == "") return "";
    //yyyy-mm-dd
    return `${date?.split("T")[0] ?? ""}`;
  };

  static dateTimeToString = (date) => {
    if (date == "") return "";
    //yyyy-mm-dd
    return `${date?.split("T")[0] ?? ""} ${
      date?.split("T")[1]?.substring(0, 5) ?? ""
    }`;
  };
}

//module.exports = { MyDateTime };
