export const SpedisciOnlineStatusEnum = {
  CONSEGNATA: "CONSEGNATA",
  "RESO AL MITTENTE": "RESO AL MITTENTE",
  "FERMO DEPOSITO": "FERMO DEPOSITO",
  "IN TRANSITO": "IN TRANSITO",
  CHIUSO: "CHIUSO",
  "IN GESTIONE": "IN GESTIONE",
  "IN CONSEGNA": "IN CONSEGNA",
  SPEDITA: "SPEDITA",
  "Non in consegna su disposizioni del mittente":
    "Non in consegna su disposizioni del mittente",
  "IN GIACENZA": "IN GIACENZA",
};
