import { userApi } from "./userApi";

const shipmentReturnToSenderApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllShipmentsReturnToSender: builder.query({
      query: ({ page, rowsPage, filter, searchText }) => ({
        url: `shipment-return-to-sender/get-all/${page}/${rowsPage}`,
        method: "POST",
        body: { filter, searchText },
      }),
      providesTags: ["ShipmentsReturnToSender"],
    }),
    insertShipmentReturnToSenderByTracking: builder.mutation({
      query: (trackingNumber) => ({
        url: `shipment-return-to-sender/insert-by-tracking/${trackingNumber}`,
        method: "POST",
      }),
      invalidatesTags: ["ShipmentsReturnToSender"],
    }),
    updateShipmentReturnToSender: builder.mutation({
      query: ({ shipmentId, isConfirmed }) => ({
        url: `shipment-return-to-sender/update`,
        method: "PUT",
        body: { shipmentId, isConfirmed },
      }),
      invalidatesTags: ["ShipmentsReturnToSender"],
    }),
    deleteShipmentReturnToSender: builder.mutation({
      query: (shipmentId) => ({
        url: `shipment-return-to-sender/delete/${shipmentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ShipmentsReturnToSender"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllShipmentsReturnToSenderQuery,
  useInsertShipmentReturnToSenderByTrackingMutation,
  useUpdateShipmentReturnToSenderMutation,
  useDeleteShipmentReturnToSenderMutation,
} = shipmentReturnToSenderApi;
