import { UserCircleIcon, RefreshIcon } from "@heroicons/react/outline";
import { useSynchronizeCatalogMutation } from "../services/catalogApi";
import { ListShippingPriceList } from "../components/shipment/ShippingPriceList/ListShippingPriceList";
import { ListProductsPriceList } from "../components/shipment/ProductsPriceList/ListProductsPriceList";
import ListConfirmOrderPriceList from "../components/ListConfirmOrderPriceList";
const secondaryNavigation = [
  { name: "Generale", href: "#", icon: UserCircleIcon, current: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SettingsPage() {
  const [synchronizeCatalog, { isLoading, isSuccess, isError, error }] =
    useSynchronizeCatalogMutation();

  const handleSynchronizeCatalog = async (e) => {
    e.preventDefault();
    await synchronizeCatalog();
  };

  return (
    <>
      <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-64 lg:flex-none lg:border-0 lg:py-20">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul
              role="list"
              className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
            >
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <a
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-50 text-green-facile"
                        : "text-gray-700 hover:text-green-facile hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-green-facile"
                          : "text-gray-400 group-hover:text-green-facile",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Catalogo
              </h2>
              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    {" "}
                    <div className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                      Sincronizza
                    </div>
                    {/* <div className="text-gray-900"></div> */}
                    <button
                      onClick={(e) => handleSynchronizeCatalog(e)}
                      type="button"
                      className="font-semibold text-green-facile hover:text-green-facile"
                    >
                      <RefreshIcon
                        className={classNames(
                          isLoading ? "animate-spinreverse" : "",
                          "h-6 w-6 shrink-0 text-green-facile"
                        )}
                        aria-hidden="true"
                      />
                    </button>
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mt-10">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Listini Spedizione
              </h2>
              <ListShippingPriceList />
            </div>
          </div>
          {/* <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mt-10">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Listini Prodotti
              </h2>
              <ListProductsPriceList />
            </div>
          </div> */}
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none mt-10">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Listini Conferme Ordine
              </h2>
              <ListConfirmOrderPriceList />
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
