import React from "react";
import { useGetAllConfirmOrderPriceListQuery } from "../services/confirmOrderPriceListApi";
import SearchableDropdown from "./custom/SearchableDropDown";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function DropDownConfirmOrderPriceList({ onSelectedPriceList, selectedValue }) {
  const { priceLists, isLoading, isSuccess, isError, error } =
    useGetAllConfirmOrderPriceListQuery(undefined, {
      selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
        var priceLists = data?.map((pl) => ({
          id: pl.id,
          name: `${pl?.name} (${pl?.price}€)`,
        }));
        return {
          priceLists: priceLists
            ? [{ id: 0, name: "Nessuno" }, ...priceLists]
            : [],
          isLoading,
          isSuccess,
          isError,
          error,
        };
      },
    });

  const onChange = (priceList) => {
    //setValue(customer);
    onSelectedPriceList(priceList);
  };

  return (
    isSuccess && (
      <div className="text-left">
        <span className="block text-sm font-medium leading-6 text-gray-900">
          Listino Conferma Ordine
        </span>
        <SearchableDropdown
          selectedVal={selectedValue ? selectedValue["name"] : ""}
          id={"id"}
          label={"name"}
          options={priceLists}
          handleChange={onChange}
        />
      </div>
    )
  );
}

export default DropDownConfirmOrderPriceList;
