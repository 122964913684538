import React, { useState } from "react";

const ShowMoreLess = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const renderText = () => {
    if (isExpanded) {
      return text;
    }
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  };

  return (
    <div>
      <p
        className={`text-md ${
          isExpanded ? "text-gray-900" : "text-gray-500"
        } poppins`}
      >
        {renderText()}
      </p>
      <div className="w-full flex justify-end underline text-green-600 font-bold cursor-pointer">
        {text.length > maxLength && (
          <p href="" onClick={toggleExpanded}>
            {isExpanded ? "Mostra meno" : "Mostra di più"}
          </p>
        )}
      </div>
    </div>
  );
};

export default ShowMoreLess;
