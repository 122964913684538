import { React, useEffect } from "react";
import { Hero } from "../components/homepage/Hero";
import Faqs from "../components/homepage/Faqs";
import Features from "../components/homepage/Features";
import FeaturedProduct from "../components/homepage/FeaturedProduct";
import SubscriptionsPlan from "../components/pricing/SubscriptionsPlan";
import Partners from "../components/homepage/Partners";
import DashboardImages from "../components/homepage/DashboardImages";
import BulletPoints from "../components/homepage/BulletPoints";
import Review from "../components/homepage/Reviews";
import Footer from "../components/homepage/Footer";
import { BackToTopButton } from "../components/homepage/BackToTopButton";
import ScrollToTop from "react-scroll-to-top";
import { useNavigate } from "react-router-dom";
import logo from "../images/logo_trasp.png";
const HomePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/login", { replace: true });
  }, []);
  return (
    <main className="flex justify-center items-center w-full h-screen">
      <img src={logo} />

      {/* <Hero />
      <FeaturedProduct />
      <SubscriptionsPlan />
      <Features />
      <DashboardImages />
    
      <Review />
      <Faqs />
      <Footer />
      <BackToTopButton /> */}
    </main>
  );
};

export default HomePage;
