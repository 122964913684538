//export const baseUrl = "https://localhost:3500";
export const baseUrl = "https://server.codfacile.com/";

export const stripeBillingPortal =
  "https://billing.stripe.com/p/login/9AQ5nc16h46m9tC9AA";

export const urlSpedisciOnlineCreateLDV =
  "https://codfacile.spedisci.online/api/v2/shipping/create";

export const imagesUrl = "https://images.codfacile.com/";

export const DEV = "NO";
