import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { DotsVerticalIcon } from "@heroicons/react/solid";
import { useGetAllCustomerQuery } from "../../services/customerApi";
import { LoadingIcon } from "../custom/LoadingIcon";
import EditCustomerPopup from "./EditCustomerPopup";
import PopupWireTransfer from "./PopupWireTransfer";
import { CashIcon, PaperAirplaneIcon } from "@heroicons/react/outline";
import PopupSelectionCustomerProducts from "../PopupSelectionCustomerProducts";
import BadgeComponent from "../custom/BadgeComponent";

export const ListCustomer = () => {
  const [searchValue, setSearchValue] = useState("");
  const [customer, setCustomer] = useState();
  const [openEditCustomerPopup, setOpenEditCustomerPopup] = useState(false);
  const [openPopupWireTransfer, setOpenPopupWireTransfer] = useState(false);
  const [showPopupProducts, setShowPopupProducts] = useState(false);
  const [customerSeeProducts, setCustomerSeeProducts] = useState({});
  const {
    data: customers,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllCustomerQuery("%20", {
    selectFromResult: ({ data, error, isLoading, isSuccess, isError }) => {
      var customers = [];
      if (isSuccess) {
        customers = data?.filter(
          (item) =>
            `${item.name} ${item.surname}`
              .toLowerCase()
              .includes(searchValue.toString().toLowerCase()) ||
            (item.company == null ? "" : item.company.businessName ?? "")
              .toLowerCase()
              .includes(searchValue.toString().toLowerCase()) ||
            (item.company == null ? "" : item.company.vatNumber ?? "")
              .toLowerCase()
              .includes(searchValue.toString().toLowerCase()) ||
            (item.company == null ? "" : item.company.fiscalCode ?? "")
              .toLowerCase()
              .includes(searchValue.toString().toLowerCase()) ||
            (item.username ?? "")
              .toLowerCase()
              .includes(searchValue.toString().toLowerCase()) ||
            (item.company.phone ?? "")
              .toLowerCase()
              .includes(searchValue.toString().toLowerCase()) ||
            (item.email ?? "")
              .toLowerCase()
              .includes(searchValue.toString().toLowerCase())
        );
      }

      return {
        data: customers,
        error,
        isLoading,
        isSuccess,
        isError,
      };
    },
  });

  let content;
  if (isLoading) {
    content = <LoadingIcon color={"text-gray-900"} />;
  } else if (isSuccess) {
    content = (
      <>
        <EditCustomerPopup
          customer={customer}
          setCustomer={setCustomer}
          open={openEditCustomerPopup}
          setOpen={setOpenEditCustomerPopup}
        />
        <PopupSelectionCustomerProducts
          open={showPopupProducts}
          setOpen={setShowPopupProducts}
          companyId={customerSeeProducts?.company?.id ?? 0}
        />
        <ul role="list" className="divide-y divide-gray-100">
          {customers?.map((customer) => (
            <li
              onDoubleClick={() => {
                setCustomer(customer);
                setOpenEditCustomerPopup(true);
              }}
              key={customer.id}
              className="flex justify-between gap-x-6 py-5 cursor-pointer flex-wrap gap-2 sm:gao-0"
            >
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex gap-2 items-center">
                  <div>
                    {(customer.stripeSubscription?.subscription?.title ?? "") !=
                      "" && (
                      <BadgeComponent
                        text={
                          customer.stripeSubscription?.subscription?.title ?? ""
                        }
                        background={"green"}
                        color={"green"}
                      />
                    )}
                  </div>
                  <div>
                    <p className="text-sm font-semibold leading-6 text-gray-900 flex gap-2">
                      <a className="hover:underline">{`${customer.name} ${customer.surname}`}</a>{" "}
                      {(customer?.company?.companyPromotionCode[0]
                        ?.promotionCode?.aliasCode ?? "") !== "" && (
                        <span className="inline-flex items-center rounded-full bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                          {
                            customer?.company?.companyPromotionCode[0]
                              ?.promotionCode?.aliasCode
                          }
                        </span>
                      )}
                    </p>
                    <p className="mt-1 flex text-xs leading-5 text-gray-500">
                      <a
                        href={`mailto:${customer.email}`}
                        className="truncate hover:underline"
                      >
                        {customer.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex flex-wrap shrink-0 items-center gap-x-6">
                <button
                  onClick={() => {
                    setCustomerSeeProducts(customer);
                    setShowPopupProducts(!showPopupProducts);
                  }}
                  type="button"
                  className="gap-2 inline-flex items-center rounded-full bg-orange-100 px-2 py-1 text-xs font-medium text-orange-600"
                >
                  Assegna Prezzo Prodotti <CashIcon className="w-4 h-4" />
                </button>
                <button
                  onClick={() => {
                    setOpenPopupWireTransfer(true);
                    setCustomer(customer);
                  }}
                  type="button"
                  className="gap-2 inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-600"
                >
                  Bonifica Rimesse <CashIcon className="w-4 h-4" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </>
    );
  } else if (isError) {
    content = <p>{error}</p>;
  }

  return (
    <>
      <div>
        <div className="mt-2">
          <input
            type="text"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            id="search-customer"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 pl-2"
            placeholder="Cerca clienti..."
          />
        </div>
      </div>
      {content}
      <PopupWireTransfer
        open={openPopupWireTransfer}
        setOpen={setOpenPopupWireTransfer}
        userId={customer?.id}
      />
    </>
  );
};
