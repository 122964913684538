import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Elements } from "@stripe/react-stripe-js";
import { useGetConfigQuery } from "../../services/stripeApi";
import { loadStripe } from "@stripe/stripe-js";
import { CheckoutSubscriptionForm } from "./CheckoutSubscriptionForm";

export default function PopupCheckoutSubscription({
  subscriptionPrice,
  open,
  setOpen,
}) {
  const [stripePromise, setStripePromise] = useState(null);
  const { data, isLoading, isSuccess, isError, error, isFetching } =
    useGetConfigQuery(undefined, {
      refetchOnMountOrArgChange: true,
      skip: !open,
    });

  useEffect(() => {
    if (isSuccess) {
      const { publishableKey } = data;
      setStripePromise(loadStripe(publishableKey));
    }
  }, [isFetching]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                {stripePromise && (
                  <Elements stripe={stripePromise}>
                    <CheckoutSubscriptionForm
                      subscriptionPrice={subscriptionPrice}
                    />
                  </Elements>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
