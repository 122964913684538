import { userApi } from "./userApi";

const wireTransferApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    // getOneCustomer: builder.query({
    //   query: ({ id }) => `customer/get-one/${id}`,
    // }),
    insertWireTransfer: builder.mutation({
      query: ({ wireTransfer }) => ({
        url: `wire-transfer/insert`,
        method: "POST",
        body: { wireTransfer },
      }),
      invalidatesTags: ["Remittance", "SummaryBalance"],
    }),
  }),
  overrideExisting: false,
});

export const { useInsertWireTransferMutation } = wireTransferApi;
