import React from "react";
import TermsAndConditionsText from "../components/TermsAndConditionsText";

function TerminiCondizioniPage() {
  return (
    <div className="sm:px-48 py-16 px-4">
      <TermsAndConditionsText />
    </div>
  );
}

export default TerminiCondizioniPage;
