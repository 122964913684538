import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";
import { usePaySubscriptionMutation } from "../services/stripeApi";
import PopupCheckoutSubscription from "../components/pricing/PopupCheckoutSubscription";
import SubscriptionsPlan from "../components/pricing/SubscriptionsPlan";

const frequencies = [
  { value: "monthly", label: "Mensile", priceSuffix: "/mese" },
  { value: "annually", label: "Annuale", priceSuffix: "/anno" },
];
const tiers = [
  {
    name: "Trial",
    id: "tier-trial",
    href: "#",
    price: "€0,99",
    description:
      "Crediamo nella qualità del nostro servizio e per questo ti offriamo un mese di prova per un caffè.",
    features: [
      { text: "15 ordini", available: true },
      { text: "Accesso al catalogo", available: true },
      { text: "Supporto Whatsapp", available: false },
      { text: "Integrazione Shopify", available: false },
    ],
    mostPopular: false,
    subscriptionPlan: "price_1Oca9tG1z4xmFCxdDqhHgSId",
  },
  {
    name: "Basic",
    id: "tier-basic",
    href: "#",
    price: "€49.90",
    description:
      "La giusta soluzione per avere più controllo del tuo e-commerce e una gestione comoda dei tuoi ordini",
    features: [
      { text: "Ordini Illimitati", available: true },
      { text: "Accesso al catalogo", available: true },
      { text: "Supporto Whatsapp/E-mail", available: true },
      { text: "Integrazione Shopify", available: true },
    ],
    mostPopular: true,
    subscriptionPlan: "price_1Ocb2IG1z4xmFCxd5A7CN8AV",
  },
  {
    name: "Pegaso",
    id: "tier-pegaso",
    href: "#",
    price: "€134.90",
    description:
      "Pensiamo a tutto noi. La soluzione perfetta per dare un boost eccezionale alla tua attività online.",
    features: [
      { text: "Tutto il Basic", available: true },
      { text: "5% Sconto sulle Spedizioni", available: true },
      { text: "Assistenza Prioritaria", available: true },
      { text: "Newsletter con prodotti in trend", available: true },
      { text: "15€ credito per campioni omaggio", available: true },
    ],
    mostPopular: false,
    subscriptionPlan: "price_1Ocb2bG1z4xmFCxdSVpoRp7a",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PricingPage() {
  return <SubscriptionsPlan />;
}
