import React from "react";

function RowsPageShopifyOrder({ setRowsPage, rowsPage }) {
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        onClick={() => setRowsPage(20)}
        type="button"
        className={`relative inline-flex items-center rounded-l-md ${
          rowsPage == 20
            ? "bg-green-500 hover:bg-green-500 text-white ring-green-500"
            : "bg-white hover:bg-gray-50 text-gray-900 ring-gray-300"
        }  px-3 py-2 text-sm font-semibold  ring-1 ring-inset   focus:z-10`}
      >
        20
      </button>
      <button
        onClick={() => setRowsPage(50)}
        type="button"
        className={`relative -ml-px inline-flex items-center ${
          rowsPage == 50
            ? "bg-green-500 hover:bg-green-500 text-white ring-green-500"
            : "bg-white hover:bg-gray-50 text-gray-900 ring-gray-300"
        } px-3 py-2 text-sm font-semibold  ring-1 ring-inset   focus:z-10`}
      >
        50
      </button>
      <button
        onClick={() => setRowsPage(100)}
        type="button"
        className={`relative -ml-px inline-flex ${
          rowsPage == 100
            ? "bg-green-500 hover:bg-green-500 text-white ring-green-500"
            : "bg-white hover:bg-gray-50 text-gray-900 ring-gray-300"
        } items-center  px-3 py-2 text-sm font-semibold  ring-1 ring-inset   focus:z-10`} //rounded-r-md
      >
        100
      </button>
      <button
        onClick={() => setRowsPage(200)}
        type="button"
        className={`relative -ml-px inline-flex ${
          rowsPage == 200
            ? "bg-green-500 hover:bg-green-500 text-white ring-green-500"
            : "bg-white hover:bg-gray-50 text-gray-900 ring-gray-300"
        } items-center rounded-r-md px-3 py-2 text-sm font-semibold  ring-1 ring-inset   focus:z-10`}
      >
        200
      </button>
    </span>
  );
}

export default RowsPageShopifyOrder;
