import React from "react";

function RowCardWithLinks({
  withImage,
  imageUrl,
  title,
  subTitle,
  trailingTitle,
  trailingSubTitle,
  actionButtons,
  onClick,
}) {
  return (
    <li className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6">
      <div className="flex min-w-0 gap-x-4">
        {withImage && (
          <img
            className="h-12 w-12 flex-none rounded-full bg-gray-50"
            src={imageUrl ?? ""}
            alt=""
          />
        )}
        <div className="min-w-0 flex-auto ">
          <p className="text-sm font-semibold leading-6 text-gray-900 ">
            <span className="inset-x-0 -top-px bottom-0" />
            {title}
          </p>
          <p className="mt-1 flex text-xs leading-5 text-gray-500">
            {subTitle}
          </p>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          {trailingTitle}
          {trailingSubTitle}
        </div>

        {actionButtons}
      </div>
    </li>
  );
}

export default RowCardWithLinks;
