import { useEffect, useState } from "react";
import {
  useGetOrderPagesMutation,
  useGetShopifyOrdersMutation,
  //useLazyGetShopifyOrdersQuery,
} from "../services/userApi";
import { useCreateLDVMutation } from "../services/spedisciOnlineApi";
import { ErrorBanner } from "../components/custom/ErrorBanner";
import { LoadingIcon } from "../components/custom/LoadingIcon";
import { useSelector } from "react-redux";
import { usePrintWaybillShipmentsMutation } from "../services/shipmentApi";
import NavShopifyOrders from "../components/order/NewShopifyOrderList/NavShopifyOrder";
import { DotsHorizontalIcon, SearchIcon } from "@heroicons/react/solid";
import DropDown from "../components/order/NewShopifyOrderList/DropDown";
import moment from "moment";
import ToggleButton from "../components/custom/ToggleButton";
import { useImportAllOrderMutation } from "../services/orderApi";
import RadioGroup from "../components/custom/RadioGroup";
import ListShopifyOrderToConfirm from "../components/order/NewShopifyOrderList/ListShopifyOrderToConfirm";
import { ConfirmOrderStatusRadioButtons } from "../enums/ConfirmOrderStatusEnums";
import Select from "react-select";
import { useGetAllCustomerQuery } from "../services/customerApi";
import { useGetAllStoreQuery } from "../services/shopifyApi";
const options = [
  { id: 0, name: "Tutto" },
  { id: 1, name: "Si" },
  { id: 2, name: "No" },
];

export const OrderToConfirmPage = () => {
  const [showOrderProducts, setShowOrderProducts] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [selectedOrderToPrint, setSelectedOrderToPrint] = useState([]);
  const [page, setPage] = useState(1);
  const [pageRows, setPageRows] = useState(20);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [printingMode, setPrintingMode] = useState(false);
  const [ldvGenerated, setLdvGenerated] = useState([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [filterConfirmOrderStatus, setFilterConfirmOrderStatus] = useState(
    ConfirmOrderStatusRadioButtons[1]
  );
  /* FILTRI */
  const [showFailedShipments, setShowFailedShipments] = useState(false);
  const [filterCashOnDelivery, setFilterCashOnDelivery] = useState(options[1]);
  const [filterPaidOrder, setFilterPaidOrder] = useState(options[0]);
  const [filterShippingSent, setFilterShippingSent] = useState(options[0]);
  const [filterIsPrinted, setFilterIsPrinted] = useState(options[0]);
  const [filterStore, setFilterStore] = useState();
  const [filterCustomer, setFilterCustomer] = useState();
  const [searchText, setSearchText] = useState("");
  const [filterStartDate, setFilterStartDate] = useState(
    moment().add(-30, "days").format("yyyy-MM-DD")
  );
  const [filterEndDate, setFilterEndDate] = useState(
    moment().format("yyyy-MM-DD")
  );
  /* FILTRI */

  var {
    stores,
    isLoading: isLoadingStores,
    isSuccess: isSuccessStores,
    isError: isErrorStores,
    error: errorStores,
  } = useGetAllStoreQuery(undefined, {
    selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
      var stores = data?.map((store) => ({
        value: store,
        label:
          (store?.aliasStore ?? "") == ""
            ? store?.storeName
            : store?.aliasStore,
      }));

      return {
        stores,
        isLoading,
        isSuccess,
        isError,
        error,
      };
    },
  });

  var {
    customers,
    isLoading: loadingCustomers,
    isSuccess: successCustomers,
    isError: errorCustomersEvent,
    error: errorCustomersText,
  } = useGetAllCustomerQuery("%20", {
    selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
      var customers = data?.map((cus) => ({
        value: cus,
        label: `${cus?.name} ${cus?.surname}`,
      }));
      return {
        customers,
        isLoading,
        isSuccess,
        isError,
        error,
      };
    },
  });

  const [getOrderPages, { data: ordersLength }] = useGetOrderPagesMutation();

  const auth = useSelector((state) => state.auth);
  const [
    getShopifyOrders,
    { data: shopifyOrders, error, isLoading, isSuccess, isError },
  ] = useGetShopifyOrdersMutation(); //useLazyGetShopifyOrdersQuery();

  const [
    importAllOrder,
    {
      isLoading: loadingImport,
      isSuccess: successImport,
      isError: importError,
      error: errorImport,
    },
  ] = useImportAllOrderMutation();

  const getAllOrders = () => {
    const filter = {
      searchText,
      toPrint: printingMode,
      isPrinted:
        filterIsPrinted.id == 1
          ? true
          : filterIsPrinted.id == 2
          ? false
          : undefined,
      isCashOnDelivery:
        filterCashOnDelivery.id == 1
          ? true
          : filterCashOnDelivery.id == 2
          ? false
          : undefined,
      isPaidOrder:
        filterPaidOrder.id == 1
          ? true
          : filterPaidOrder.id == 2
          ? false
          : undefined,
      confirmOrderStatus: filterConfirmOrderStatus.id,
      isSentShipping:
        filterShippingSent.id == 1
          ? true
          : filterShippingSent.id == 2
          ? false
          : undefined,
      dateRange: { startDate: filterStartDate, endDate: filterEndDate },
      showFailedShipments: showFailedShipments,
      shopifyStoreId: filterStore?.value?.id ?? 0,
      codfacileCustomer: filterCustomer?.value?.id ?? 0,
      fulfillmentRequest: true,
    };
    getOrderPages({ filter });
    getShopifyOrders({
      page,
      pageRows,
      filter: filter,
    });
  };

  useEffect(() => {
    if (!isGenerating) getAllOrders();
  }, [
    page,
    pageRows,
    filterCashOnDelivery,
    filterShippingSent,
    filterPaidOrder,
    filterStartDate,
    filterEndDate,
    printingMode,
    filterIsPrinted,
    isGenerating,
    showFailedShipments,
    filterStore,
    filterCustomer,
    filterConfirmOrderStatus,
  ]);

  const [
    createLDV,
    {
      isLoading: createLoading,
      isSuccess: createSuccesss,
      isError: isCreateError,
      error: createError,
    },
  ] = useCreateLDVMutation();

  const [
    printWaybillShipments,
    {
      data: base64Pdf,
      isLoading: printLoading,
      isSuccess: printSuccess,
      isError: isPrintError,
      error: printError,
    },
  ] = usePrintWaybillShipmentsMutation();

  const handleAddItem = (checked, item) => {
    let items = [...selectedOrder];
    var ids = items.map((ele) => ele.id);
    if (checked) items.push(item);
    else {
      var index = ids.indexOf(item.id);
      items.splice(index, 1);
    }

    setSelectedOrder(items);
  };

  const handleItemToPrint = (e, item) => {
    let items = [...selectedOrderToPrint];
    var ids = items.map((ele) => ele.id);
    if (e.target.checked) items.push(item);
    else {
      var index = ids.indexOf(item.id);
      items.splice(index, 1);
    }

    setSelectedOrderToPrint(items);
  };

  const handleSelectAllItemToPrint = (e) => {
    if (
      selectedOrderToPrint.length ==
      shopifyOrders?.filter((so) => so.tracking_number).length
    ) {
      setSelectedOrderToPrint([]);
    } else {
      setSelectedOrderToPrint(
        shopifyOrders?.filter((so) => so.tracking_number)
      );
    }
  };

  const onClick = async () => {
    var ldvs = [];
    setIsGenerating(true);
    for (let i = 0; i < selectedOrder.length; i++) {
      const order = selectedOrder[i];
      if (order.tracking_number == null) {
        await createLDV(order).finally(() => {
          ldvs = [...ldvs, order];
          if (ldvs.length == selectedOrder.length) {
            setSelectedOrder([]);
            setLdvGenerated([]);
          } else {
            setLdvGenerated(ldvs);
          }
        });
      }
    }
    setIsGenerating(false);
  };

  const onPrintClick = async () => {
    if (selectedOrderToPrint.length > 0) {
      let trackingNumbers = selectedOrderToPrint.map(
        (orderToPrint) => orderToPrint.tracking_number
      );
      setSelectedOrderToPrint([]);
      await printWaybillShipments({ trackingNumbers });
    }
    setPrintingMode(!printingMode);
  };

  const handleCancelPrintOrder = async () => {
    setSelectedOrderToPrint([]);
    setPrintingMode(false);
  };

  let content;
  if (isLoading) {
    content = <LoadingIcon color={"text-gray-900"} />;
  } else if (isSuccess) {
    //|| isLoading) {
    content = (
      <>
        <NavShopifyOrders
          numOrders={ordersLength}
          page={page}
          rowsPage={pageRows}
          setPage={setPage}
          setRowsPage={setPageRows}
        />
        <ListShopifyOrderToConfirm
          getAllOrders={getAllOrders}
          showOrderProducts={showOrderProducts}
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          error={error}
          orders={
            printingMode
              ? shopifyOrders?.filter((so) => so.tracking_number)
              : shopifyOrders
          }
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          selectedOrderToPrint={selectedOrderToPrint}
          printingMode={printingMode}
          handleAddItem={handleAddItem}
          handleItemToPrint={handleItemToPrint}
        />
      </>
    );
  } else if (isError) {
    setErrorMessage(error.data);
  }

  const printPdf = (base64Pdf) => {
    const linkSource = `data:application/pdf;base64,${base64Pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `LETTEREDIVETTURA.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };
  const [showPopupSelectStore, setShowPopupSelectStore] = useState(false);

  useEffect(() => {
    if (printSuccess) printPdf(base64Pdf);
    if (isPrintError) console.log("errore stampa: ", printError);
  }, [printLoading]);

  useEffect(() => {
    if (isCreateError) {
      setErrorMessage(createError.data.message);
      setShowErrorMessage(true);
    }
  }, [createLoading]);

  return (
    <main className="px-4">
      {" "}
      <div className="flex flex-wrap gap-2 items-end">
        <div className="flex gap-2 mt-2 flex-wrap items-end">
          <div>
            <DropDown
              label={"Contrassegno"}
              items={options}
              selectedItem={filterCashOnDelivery}
              onChange={setFilterCashOnDelivery}
            />
          </div>
          <div>
            <DropDown
              label={"Pagati"}
              items={options}
              selectedItem={filterPaidOrder}
              onChange={setFilterPaidOrder}
            />
          </div>
          <div>
            <DropDown
              label={"Spedito"}
              items={options}
              selectedItem={filterShippingSent}
              onChange={setFilterShippingSent}
            />
          </div>
          {auth.roles.includes(1) ? (
            <div>
              <DropDown
                label={"Stampati"}
                items={options}
                selectedItem={filterIsPrinted}
                onChange={setFilterIsPrinted}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="flex-col ">
            <span className="block text-sm font-medium leading-6 text-gray-900">
              Negozio
            </span>
            {isSuccessStores && (
              <Select
                placeholder={"Cerca Negozio..."}
                isSearchable={true}
                options={stores}
                onChange={setFilterStore}
                isClearable={true}
              />
            )}
          </div>
          {auth.roles.includes(1) ? (
            <div className="flex-col">
              <span className="block text-sm font-medium leading-6 text-gray-900">
                Cliente
              </span>
              <Select
                placeholder={"Cerca Cliente..."}
                isSearchable={true}
                options={customers}
                onChange={setFilterCustomer}
                isClearable={true}
              />
            </div>
          ) : (
            <></>
          )}
          <div className="flex gap-2 items-center">
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Data Inizio Ordine
              </label>
              <input
                className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                type="date"
                value={filterStartDate}
                onChange={(e) => setFilterStartDate(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Data Fine Ordine
              </label>
              <input
                className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                type="date"
                value={filterEndDate}
                onChange={(e) => setFilterEndDate(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 flex-1">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") getAllOrders();
            }}
            type="text"
            name="name"
            id="name"
            className="block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
            placeholder="Cerca in ordini"
          />
          <button
            onClick={(e) => getAllOrders()}
            type="button"
            className="rounded-xl bg-green-600 p-2 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600
           bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm  text-center "
          >
            <SearchIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
      </div>
      <div className="mt-2 flex flex-wrap gap-2 items-center">
        <div>
          <RadioGroup
            defaultItem={filterConfirmOrderStatus}
            items={ConfirmOrderStatusRadioButtons}
            onChange={(item) => setFilterConfirmOrderStatus(item)}
          />
        </div>
        <ToggleButton
          enabled={showOrderProducts}
          onChangeEvent={(e) => {
            setShowOrderProducts(e);
          }}
          text={"Mostra Prodotti nell'ordine"}
        />
        {/* <ToggleButton
          enabled={showFailedShipments}
          onChangeEvent={(e) => {
            setShowFailedShipments(e);
          }}
          text={`Mostra Spedizioni Fallite ${
            showFailedShipments ? `(${shopifyOrders?.length ?? 0})` : ""
          }`}
        /> */}
      </div>
      <div>{content}</div>
      <ErrorBanner
        message={errorMessage}
        show={showErrorMessage}
        setShow={setShowErrorMessage}
      />
    </main>
  );
};
