import React from "react";
import { MyDateTime } from "../functions/date_time";

function RowCashFlow({ movement }) {
  return (
    <tr className="even:bg-gray-50">
      <th
        scope="row"
        className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 "
      >
        {MyDateTime.dateToString(movement?.created_date ?? "")}
      </th>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {movement?.transaction_type ?? ""}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {movement?.tracking_number ?? ""}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {movement?.product_name ?? ""}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {movement?.total_amount ?? 0}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {movement?.qty ?? 0}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {movement?.store_name ?? ""}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {movement?.customer_to_ship ?? ""}
      </td>
    </tr>
  );
}

export default RowCashFlow;
