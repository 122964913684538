import { userApi } from "./userApi";

const customerApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCustomer: builder.query({
      query: (searchText) => `customer/get-all/${searchText}`,
      providesTags: ["Customer"],
    }),
    getOneCustomer: builder.query({
      query: ({ id }) => `customer/get-one/${id}`,
    }),
    updateCustomer: builder.mutation({
      query: ({ customer }) => ({
        url: `customer/update`,
        method: "PUT",
        body: { customer },
      }),
      invalidatesTags: ["Customer"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllCustomerQuery,
  useLazyGetAllCustomerQuery,
  useGetOneCustomerQuery,
  useUpdateCustomerMutation,
} = customerApi;
