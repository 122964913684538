function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export const PrintPdfButton = ({
  textButton,
  onClick,
  isLoading,
  Icon,
  backgroundClass,
  colorClass,
}) => {
  return (
    <>
      <button
        onClick={(e) => onClick(e)}
        type="button"
        className={`${
          backgroundClass ??
          "bg-green-facile hover:bg-green-facile focus:ring-green-facile"
        } ${
          colorClass ?? "text-white"
        } inline-flex gap-2 items-center px-5 py-2.5 border border-transparent text-base font-medium rounded-md shadow-sm   focus:outline-none focus:ring-2 focus:ring-offset-2 `}
      >
        {Icon == undefined ? <></> : <Icon className="h-6 w-6" />}
        {textButton}
      </button>
    </>
  );
};

<svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24"></svg>;
