import { userApi } from "./userApi";

const cashFlowApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAll: builder.mutation({
      query: (filter) => ({
        url: `cash-flow/get-all`,
        method: "POST",
        body: filter,
      }),
    }),
    getAllTransactions: builder.mutation({
      query: ({ userId, startDate, endDate }) => ({
        url: `cash-flow/get-transactions`,
        method: "POST",
        body: { userId, startDate, endDate },
      }),
    }),
    getSummaryBalancePdf: builder.mutation({
      query: ({ userId, startDate, endDate }) => ({
        url: `cash-flow/get-customer-week-summary`,
        method: "POST",
        body: { userId, startDate, endDate },
      }),
    }),
    getCustomersSummaryBalance: builder.query({
      query: () => `cash-flow/customer-summary-balance`,
      providesTags: ["SummaryBalance"],
    }),
    getBalance: builder.query({
      query: () => `cash-flow/balance`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllMutation,
  useGetBalanceQuery,
  useLazyGetBalanceQuery,
  useLazyGetCustomersSummaryBalanceQuery,
  useGetAllTransactionsMutation,
  useGetSummaryBalancePdfMutation,
} = cashFlowApi;
