import React, { useState } from "react";
import InputToggleEdit from "./catalog/InputToggleEdit";
import { CurrencyEuroIcon } from "@heroicons/react/outline";
import { useUpdateProductPriceListMutation } from "../services/catalogApi";
import SimpleNotification from "./SimpleNotification";
import BannerMessage from "./order/BannerMessage";
import { ErrorBanner } from "./custom/ErrorBanner";

function CardMiniProductSetPrice({ prod, companyId }) {
  const [product, setProduct] = useState(prod);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [messageError, setMessageError] = useState("");

  const [updateProductPriceList, { isLoading, isSuccess, isError, error }] =
    useUpdateProductPriceListMutation();

  // useState(() => {
  //   if (isSuccess) setShowSuccess(true);
  // }, [isLoading]);

  const onUpdatePrice = (companyId, product) => {
    updateProductPriceList({ companyId, product }).then((res) => {
      if (res.error) {
        setMessageError(res?.error?.data?.message);
        setShowError(true);
        return;
      }
      setShowSuccess(true);
    });
  };

  return (
    <div className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
      <ErrorBanner
        show={showError}
        setShow={setShowError}
        message={messageError ?? "Errore generico imprevisto"}
      />
      <SimpleNotification show={showSuccess} setShow={setShowSuccess} />
      <div className="min-w-0 flex-1">
        <a href="#" className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-medium text-gray-900">{product.name}</p>
          <p className="truncate text-sm text-gray-500">{product.sku}</p>
        </a>
      </div>
      <div className="z-40">
        <InputToggleEdit
          onEndEdit={(isEdited) =>
            isEdited
              ? onUpdatePrice(companyId, product) //editProductCatalog(prod)
              : setProduct({
                  ...product,
                  productPriceList: prod?.productPriceList
                    ? {
                        ...prod.productPriceList,
                        price: prod?.productPriceList?.price ?? "",
                      }
                    : null,
                })
          }
          Icon={CurrencyEuroIcon}
          setValue={(price) =>
            setProduct({
              ...product,
              productPriceList: product?.productPriceList
                ? { ...product.productPriceList, price: price }
                : { price },
            })
          }
          hintTextDisable={"Inserisci Prezzo"}
          defaultValue={product?.productPriceList?.price ?? ""}
        />
      </div>
    </div>
  );
}

export default CardMiniProductSetPrice;
