import {
  CheckIcon,
  ExclamationIcon,
  RefreshIcon,
} from "@heroicons/react/solid";
import { useState } from "react";
import { ShoppingCartIcon } from "@heroicons/react/solid";
import { useGetAllProductCatalogInCartQuery } from "../../services/productCatalogInCartApi";
import { InvalidOrderPopup } from "../order/InvalidOrderPopup";
import CartPopup from "../catalog/CartPopup";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const CartMenuButton = () => {
  const [open, setOpen] = useState(false);

  const {
    data: productsCatalogInCart,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllProductCatalogInCartQuery();

  return (
    <div className="fixed bottom-3 right-3 flex flex-col items-end gap-2  z-50">
      <CartPopup
        productsCatalogInCart={productsCatalogInCart}
        open={open}
        setOpen={setOpen}
      />

      <div onClick={() => setOpen(!open)}>
        <span
          className={
            "ring-green-700 relative cursor-pointer inline-flex h-16 w-16 items-center justify-center rounded-full shadow-xl ring-2 bg-white"
          }
        >
          <span className="leading-none">
            {isLoading ? (
              <RefreshIcon className="animate-spinreverse h-8 w-8 fill-green-700" />
            ) : isSuccess ? (
              <ShoppingCartIcon className="h-8 w-8 fill-green-700 " />
            ) : (
              <></>
            )}
          </span>
          <span className="absolute right-0 top-0  h-4 w-4 rounded-full bg-green-700  ring-2 ring-green-700 flex justify-center items-center text-white">
            {productsCatalogInCart?.length ?? 0}
          </span>
          {/* {isSuccess ? (
            invalidOrders?.length > 0 ? (
              <>
                <span className="animate-ping absolute right-0 top-0 block h-4 w-4 rounded-full bg-red-500  ring-2 ring-white" />
              </>
            ) : (
              <></>
            )
          ) : (
            <></>
          )} */}
        </span>
      </div>
    </div>
  );
};
