import { CheckCircleIcon } from "@heroicons/react/outline";
import React from "react";
import { Link } from "react-router-dom";

function ConfirmProductsPage() {
  return (
    <main className="h-full flex justify-center items-center flex-col">
      <CheckCircleIcon className="h-48 w-48 text-green-500" />
      <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl text-center">
        Ordine pagato con successo.
      </h1>
      <p className="text-lg font-medium text-center">
        Ora puoi effettuare la spedizione.
      </p>
      <Link
        to="/orders"
        className="mt-2 text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2"
      >
        Vai agli ordini
      </Link>
    </main>
  );
}

export default ConfirmProductsPage;
