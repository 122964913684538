import React, { useEffect, useState } from "react";
import DropDown from "../order/NewShopifyOrderList/DropDown";
import { useGetAllSpedisciOnlineStatusQuery } from "../../services/shipmentApi";

function DropDownSpedisciOnlineStatus({ defaultValue, onSelectedStatus }) {
  const [spedisciOnlineStatus, setSpedisciOnlineStatus] =
    useState(defaultValue);

  var {
    status,
    isLoading: isLoadingStatus,
    isSuccess: isSuccessStatus,
    isError: isErrorStatus,
    error: errorStatus,
  } = useGetAllSpedisciOnlineStatusQuery(undefined, {
    skip: defaultValue == undefined,
    selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
      var status = data?.map((status) => ({
        id: status.id,
        name: status?.spedisci_online_status ?? "",
        // (store?.aliasStore ?? "") == ""
        //   ? store?.storeName
        //   : store?.aliasStore,
      }));

      return {
        status: status ? [defaultValue, ...status] : [defaultValue],
        isLoading,
        isSuccess,
        isError,
        error,
      };
    },
  });

  useEffect(() => {
    if (isSuccessStatus) {
      setSpedisciOnlineStatus(status[0]);
    }
  }, [isLoadingStatus]);

  if (defaultValue == undefined) return <></>;

  const onChange = (store) => {
    setSpedisciOnlineStatus(store);
    onSelectedStatus(store);
  };

  return (
    isSuccessStatus && (
      <div>
        <DropDown
          items={status}
          selectedItem={spedisciOnlineStatus}
          onChange={onChange}
          label={"Stato Spedizione"}
        />
      </div>
    )
  );
}

export default DropDownSpedisciOnlineStatus;
