import React, { useEffect, useState } from "react";
import "./LoaderSpinnerMain.css";
import logo_trasp from "../../images/_logo192_trasp.png";

const LoaderSpinnerMain = ({
  size = 80,
  topColor = "#00BFFF",
  bottomColor = "#FF5733",
  isLoading,
}) => {
  const [loadingState, setLoadingState] = useState(isLoading);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    setFadeOut(loadingState == false);
    //setFadeOut(true);
    setTimeout(() => {
      setLoadingState(false);
    }, 3000);
  }, [isLoading]);

  return (
    //<div className=" w-full h-screen flex justify-center items-center">
    <div
      className={`fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-white transition-opacity duration-3000 ${
        fadeOut ? "opacity-0" : "opacity-100"
      }`}
    >
      <div
        className="tail-spin relative flex items-center justify-center"
        style={{ width: size, height: size }}
      >
        <div
          className="tail-spin-circle "
          style={{
            borderColor: `${topColor} transparent transparent transparent`,
            borderBottomColor: bottomColor,
          }}
        ></div>
        <img
          src={logo_trasp}
          width={60}
          height={60}
          className="absolute w-10 h-10"
        />
      </div>
    </div>
  );
};

export default LoaderSpinnerMain;
