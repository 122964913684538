export const TableLoading = () => {
  var content = [];
  for (let x = 0; x < 1; x++) {
    content.push(
      <tr
        key={x}
        className=" text-gray-900 `shopify-order-row bg-gray-50 even:bg-gray-100"
      >
        <td className="relative py-3.5 pl-3 pr-4 sm:pr-3">
          <div className="w-3.5 h-3.5 bg-gray-200 rounded-sm"></div>
        </td>
        <td className="relative py-5 pr-6">
          <span className="bg-gray-200 text-gray-200 rounded-sm">
            09/05/2024
          </span>
        </td>
        <td className="relative py-5 pr-6">
          <span className="bg-gray-200 text-gray-200 rounded-sm">1357</span>
          <br />
          <span className="bg-gray-200 text-gray-200 rounded-sm">
            ed5tsr.myshopify.com
          </span>
        </td>
        <td className="relative py-5 pr-6">
          <span className="bg-gray-200 text-gray-200 rounded-sm">
            CE641173825
          </span>
        </td>
        <td className="relative py-5 pr-6">
          <span className="bg-gray-200 text-gray-200 rounded-sm">
            Marco Caputo
          </span>
        </td>
        <td className="py-5 pr-6 sm:table-cell">
          <span className="bg-gray-200 text-gray-200 rounded-sm">
            Denny Leo
          </span>
          <br />
          <span className="bg-gray-200 text-gray-200 rounded-sm">
            Via umberto 1 24, Lecce, 73040, LE
          </span>
        </td>
        <td className="px-3 py-3.5 sm:table-cell text-center">
          <span className="bg-gray-200 text-gray-200  rounded-sm">29.99</span>
        </td>
        <td className="px-3 py-4 text-sm text-gray-500">
          <span className="bg-gray-200 text-gray-200 rounded-sm">
            Non in consegna. In attesa di istruzioni dal mittente per eventuale
            riconsegna
          </span>
          <br />
          <span className="bg-gray-200 text-gray-200  rounded-sm">
            Tracciamento
          </span>
        </td>
        <td className="py-5 pr-6 sm:table-cell text-center font-medium text-lg ">
          <span className="bg-gray-200 text-gray-200 rounded-sm">5.8</span>
        </td>
        <td className="relative py-3.5 pl-3 pr-4 sm:pr-3 text-center ">
          <span className="bg-gray-200 text-gray-200 rounded-sm p-1">5.8</span>
        </td>
        <td className="relative py-3.5 pl-3 pr-4 sm:pr-3 text-center ">
          <span className="bg-gray-200 text-gray-200 rounded-sm p-1">5.8</span>
        </td>
      </tr>
    );
  }

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="animate-pulse w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-200 rounded-md bg-gray-100">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
          <tr>
            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
              <div className="w-3.5 h-3.5 bg-gray-200 rounded-sm"></div>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3 "
            >
              {" "}
              <span className="bg-gray-200 text-gray-200 rounded-sm">DATA</span>
            </th>

            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3"
            >
              {" "}
              <span className="bg-gray-200 text-gray-200 rounded-sm">
                Nr. Ordine
              </span>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3"
            >
              <span className="bg-gray-200 text-gray-200 rounded-sm">LDV</span>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3"
            >
              <span className="bg-gray-200 text-gray-200 rounded-sm">
                CLIENTE CODFACILE
              </span>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
            >
              <span className="bg-gray-200 text-gray-200 rounded-sm">
                Destinatario
              </span>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center"
            >
              <span className="bg-gray-200 text-gray-200 rounded-sm">COD</span>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5  text-sm font-semibold text-gray-900 "
            >
              <span className="bg-gray-200 text-gray-200 rounded-sm">
                Stato
              </span>
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center"
            >
              <span className="bg-gray-200 text-gray-200 rounded-sm">
                Costo Spedizione
              </span>
            </th>

            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
              <span className="sr-only"></span>
            </th>

            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
              <span className="sr-only"></span>
            </th>
          </tr>
        </thead>
        <tbody>{content}</tbody>
      </table>
    </div>
  );
};
