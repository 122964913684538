import { PrinterIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { usePrintWaybillShipmentsMutation } from "../../services/shipmentApi";
import { download_pdf } from "../../functions/download_pdf";
import moment from "moment/moment";
import { LoadingIcon } from "../custom/LoadingIcon";
import axios from "axios";
import { DEV, baseUrl } from "../../config/baseUrl";
import { useSelector } from "react-redux";

function PrintAllButton({ trackingNumbers }) {
  const auth = useSelector((state) => state.auth);
  const [printLoading, setPrintLoading] = useState(false);
  // const [
  //   printWaybillShipments,
  //   {
  //     data: base64Pdf,
  //     isLoading: printLoading,
  //     isSuccess: printSuccess,
  //     isError: isPrintError,
  //     error: printError,
  //   },
  // ] = usePrintWaybillShipmentsMutation();

  const printWaybillShipments = async () => {
    try {
      setPrintLoading(true);
      var slash = DEV == "SI" ? "/" : "";
      const response = await axios.post(
        `${baseUrl}${slash}shipment/pdf-to-print`,
        trackingNumbers,
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
          },
        }
      );
      console.log("response", response);
      if (response.status == 200) {
        const blob = await response.data;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "file.pdf"; // Nome del file da scaricare
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        console.error("Errore nel download del file");
      }
    } catch (error) {
      console.error("Error fetching PDF", error);
    }
    setPrintLoading(false);
  };

  return (
    <button
      onClick={async () => await printWaybillShipments()}
      type="button"
      className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
    >
      Stampa Tutto
      {printLoading ? (
        <LoadingIcon className="h-3 w-3" color={"text-white"} />
      ) : (
        <PrinterIcon className="h-4 w-4" />
      )}
    </button>
  );
}

export default PrintAllButton;
