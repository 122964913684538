import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import ListShippingPriceListWeightRange from "./ListShippingPriceListWeightRange";
import { useGetAllByShippingPriceListIdQuery } from "../../../services/shippingPriceListWeightRangeApi";
import { TableLoading } from "../LoadingTable";

export default function EditShippingPriceListPopup({
  shippingPriceListEdited,
  setShippingPriceListEdited,
  handleSubmit,
  open,
  setOpen,
}) {
  const cancelButtonRef = useRef(null);
  const handleEditButton = async (e) => {
    e.preventDefault();
    await handleSubmit(
      {
        ...shippingPriceListEdited,
      },
      shippingPriceListWeightRange
    );
  };
  const [shippingPriceListWeightRange, setShippingPriceListWeightRange] =
    useState([]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform w-full overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Listino di spedizione
                    </Dialog.Title>
                    <div className="mt-2">
                      <form
                        className="space-y-6"
                        onSubmit={(e) => handleEditButton(e)}
                      >
                        <p className="text-2xl font-bold m-0">Modifica</p>
                        <div>
                          <label
                            htmlFor="code-new-shipping-price-list"
                            className="text-left block text-sm font-medium leading-6 text-gray-900"
                          >
                            Codice
                          </label>
                          <div className="mt-2">
                            <input
                              onChange={(e) =>
                                setShippingPriceListEdited({
                                  ...shippingPriceListEdited,
                                  code: e.target.value,
                                })
                              }
                              value={
                                shippingPriceListEdited == undefined
                                  ? ""
                                  : shippingPriceListEdited.code
                              }
                              id="code-new-shipping-price-list"
                              name="code-new-shipping-price-list"
                              type="text"
                              required
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                        <div className="sm:flex gap-5">
                          <div className="flex-1">
                            <div className="flex items-center justify-between">
                              <label
                                htmlFor="description-new-shipping-price-list"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                Descrizione
                              </label>
                              <div className="text-sm"></div>
                            </div>
                            <div className="mt-2">
                              <input
                                onChange={(e) =>
                                  setShippingPriceListEdited({
                                    ...shippingPriceListEdited,
                                    description: e.target.value,
                                  })
                                }
                                value={
                                  shippingPriceListEdited == undefined
                                    ? ""
                                    : shippingPriceListEdited.description
                                }
                                id="description-new-shipping-price-list"
                                name="description-new-shipping-price-list"
                                type="text"
                                required
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>

                        <ListShippingPriceListWeightRange
                          shippingPriceListId={shippingPriceListEdited.id}
                          shippingPriceListWeightRange={
                            shippingPriceListWeightRange
                          }
                          setShippingPriceListWeightRange={
                            setShippingPriceListWeightRange
                          }
                        />

                        <div className="flex justify-start gap-3">
                          <input
                            type="checkbox"
                            checked={shippingPriceListEdited.isDefault}
                            onChange={(e) =>
                              setShippingPriceListEdited({
                                ...shippingPriceListEdited,
                                isDefault: e.target.checked,
                              })
                            }
                          />{" "}
                          Predefinito
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md bg-green-facile px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-facile focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-facile sm:col-start-2"
                          >
                            Modifica
                          </button>
                          <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => setOpen(false)}
                            ref={cancelButtonRef}
                          >
                            Annulla
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
