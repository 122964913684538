import {
  CheckCircleIcon,
  PhoneMissedCallIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import React, { useState } from "react";
import { useUpdateOrderTagsMutation } from "../services/shopifyApi";
import { ConfirmOrderStatusEnums } from "../enums/ConfirmOrderStatusEnums";
import SimpleNotification from "./SimpleNotification";
import { LoadingIcon } from "./custom/LoadingIcon";

function UpdateOrderTagsButtons({ orderId, onClick }) {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationSuccess, setNotificationSuccess] = useState(true);
  const [clickedTag, setClickedTag] = useState("");
  const [updateOrderTags, { data, isLoading, isSuccess, isError, error }] =
    useUpdateOrderTagsMutation();

  const onClickButton = async (statusTag) => {
    console.log(statusTag);
    setClickedTag(statusTag);
    updateOrderTags({ orderId, tag: statusTag })
      .then((res) => {
        if (res.error) {
          setShowNotification(true);
          setNotificationSuccess(false);
          setNotificationMessage(res.error.data.message);
          setClickedTag("");
          return;
        }
        setShowNotification(true);
        setNotificationSuccess(true);
        setNotificationMessage("Ordine aggiornato con successo");
        setClickedTag("");
      })
      .finally(() => {
        onClick(statusTag);
      });
  };

  return (
    <div className="flex flex-col gap-1">
      <SimpleNotification
        message={notificationMessage}
        show={showNotification}
        setShow={setShowNotification}
        success={notificationSuccess}
      />
      <button
        type="button"
        className="inline-flex items-center gap-x-1.5   rounded-md bg-green-500 text-white px-2.5 py-1 text-xs font-semibold  shadow-sm ring-1 ring-inset ring-green-300 hover:bg-green-400"
        onClick={() => onClickButton(ConfirmOrderStatusEnums.Confermato)}
      >
        {isLoading && clickedTag == ConfirmOrderStatusEnums.Confermato ? (
          <LoadingIcon size={"h-5 w-5"} color={"text-white"} />
        ) : (
          <CheckCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
        )}
        CONFERMATO
      </button>
      <button
        type="button"
        className="inline-flex items-center gap-x-1.5   rounded-md bg-orange-500 text-white px-2.5 py-1 text-xs font-semibold  shadow-sm ring-1 ring-inset ring-orange-300 hover:bg-orange-400"
        onClick={() => onClickButton(ConfirmOrderStatusEnums.NonRisponde)}
      >
        {isLoading && clickedTag == ConfirmOrderStatusEnums.NonRisponde ? (
          <LoadingIcon size={"h-5 w-5"} color={"text-white"} />
        ) : (
          <PhoneMissedCallIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
        )}
        NON RISPONDE
      </button>
      <button
        type="button"
        className="inline-flex items-center gap-x-1.5   rounded-md bg-red-700 text-white px-2.5 py-1 text-xs font-semibold  shadow-sm ring-1 ring-inset ring-red-500 hover:bg-red-600"
        onClick={() => onClickButton(ConfirmOrderStatusEnums.NumeroErrato)}
      >
        {isLoading && clickedTag == ConfirmOrderStatusEnums.NumeroErrato ? (
          <LoadingIcon size={"h-5 w-5"} color={"text-white"} />
        ) : (
          <XCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
        )}
        NUMERO ERRATO
      </button>
      <button
        type="button"
        className="inline-flex items-center gap-x-1.5   rounded-md bg-red-500 text-white px-2.5 py-1 text-xs font-semibold  shadow-sm ring-1 ring-inset ring-red-300 hover:bg-red-400"
        onClick={() => onClickButton(ConfirmOrderStatusEnums.Annullato)}
      >
        {isLoading && clickedTag == ConfirmOrderStatusEnums.Annullato ? (
          <LoadingIcon size={"h-5 w-5"} color={"text-white"} />
        ) : (
          <XCircleIcon aria-hidden="true" className="-ml-0.5 h-5 w-5" />
        )}
        ANNULLATO
      </button>
    </div>
  );
}

export default UpdateOrderTagsButtons;
