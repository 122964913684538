import { Fragment, useEffect, useMemo, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ArrowCircleDownIcon } from "@heroicons/react/solid";
import { useGetAllCustomerQuery } from "../../services/customerApi";
import DropDown from "../order/NewShopifyOrderList/DropDown";
import SearchableDropdown from "../custom/SearchableDropDown";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const DropDownCustomer = ({ onSelectedCustomer, selectedValue }) => {
  //const [customer, setCustomer] = useState(defaultValue);
  //const [value, setValue] = useState(defaultValue);
  var { customers, isLoading, isSuccess, isError, error } =
    useGetAllCustomerQuery("%20", {
      //skip: defaultValue == undefined,
      selectFromResult: ({ data, isLoading, isSuccess, isError, error }) => {
        var customers = data?.map((cus) => ({
          id: cus.id,
          name: `${cus?.name} ${cus?.surname}`,
        }));
        return {
          customers: customers ? customers : [],
          isLoading,
          isSuccess,
          isError,
          error,
        };
      },
    });

  // useEffect(() => {
  //   if (isSuccess) {
  //     onSelectedCustomer(customers[0]);
  //   }
  // }, [isLoading]);

  // useMemo(() => {
  //   setValue(defaultValue);
  // }, [defaultValue]);

  //if (defaultValue == undefined) return <></>;

  const onChange = (customer) => {
    //setValue(customer);
    onSelectedCustomer(customer);
  };

  return (
    isSuccess && (
      <div>
        <span className="block text-sm font-medium leading-6 text-gray-900">
          Clienti
        </span>
        <SearchableDropdown
          selectedVal={selectedValue ? selectedValue["name"] : ""}
          id={"id"}
          label={"name"}
          options={customers}
          handleChange={onChange}
        />
        {/* <DropDown
          items={customers}
          selectedItem={customer}
          onChange={onChange}
          label={"Clienti"}
        /> */}
      </div>
    )
  );
};
