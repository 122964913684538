/* This example requires Tailwind CSS v2.0+ */
export default function SecondaryButton({ text, onClick, color }) {
  return (
    <>
      <button
        onClick={(e) => onClick(e)}
        type="button"
        className={`inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md ${
          color ? color : "text-green-facile"
        } bg-green-facile hover:bg-green-facile focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-facile`}
      >
        {text}
      </button>
    </>
  );
}
