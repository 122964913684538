import { DropDownCustomer } from "../components/customer/DropDownCustomer";
import DropDownShippingPriceList from "../components/shipment/ShippingPriceList/DropDownShippingPriceList";
import { ListCustomer } from "../components/customer/ListCustomer";

export const CustomerPage = () => {
  return (
    <>
      <ListCustomer />
    </>
  );
};
