import { TrashIcon, PencilIcon } from "@heroicons/react/outline";
export const RowProductsPriceList = ({
  productsPriceList,
  setProductsPriceListToEdit,
  setProductsPriceListToDelete,
  setConfirmationMessage,
  setShowConfirmationMessage,
  setOpenEditProductsPriceListPopup,
}) => {
  return (
    <li className="flex justify-between gap-x-6 py-6">
      <div className="font-medium text-gray-900">{productsPriceList.code}</div>
      <div className="font-medium text-gray-900">
        {productsPriceList.percDiscount}
      </div>
      <div className="font-medium text-gray-900">
        <input
          type="checkbox"
          checked={productsPriceList.isDefault}
          disabled={true}
        />
      </div>
      <div className="flex gap-2">
        <button
          onClick={() => {
            setProductsPriceListToEdit(productsPriceList);
            setOpenEditProductsPriceListPopup(true);
          }}
          type="button"
          className="font-semibold text-green-facile hover:text-green-facile"
        >
          <PencilIcon className="h-6 w-6 shrink-0 text-green-facile" />
        </button>
        <button
          type="button"
          onClick={() => {
            setConfirmationMessage(
              `Sei sicuro di voler cancellare il listino prezzi spedizione col codice '${productsPriceList.code}'?`
            );
            setProductsPriceListToDelete(productsPriceList);
            setShowConfirmationMessage(true);
          }}
          className="font-semibold text-green-facile hover:text-green-facile"
        >
          <TrashIcon className="h-6 w-6 shrink-0 text-red-700" />
        </button>
      </div>
    </li>
  );
};
