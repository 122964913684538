import { useEffect, useState } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Input from "../components/catalog/Input";
import TextArea from "../components/catalog/TextArea";
import {
  useAddProductCatalogMutation,
  useEditProductCatalogMutation,
  useUploadFileFetchMutation,
} from "../services/catalogApi";
import { TrashIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseUrl } from "../config/baseUrl";
import SimpleNotification from "../components/SimpleNotification";
import SearchBar from "../components/catalog/SearchBar";
import {
  useGetAllCustomerQuery,
  useLazyGetAllCustomerQuery,
} from "../services/customerApi";
import InputToggleEdit from "../components/catalog/InputToggleEdit";
import { Checkbox } from "@mui/material";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ManageProductCatalogPage({}) {
  const location = useLocation();
  const navigate = useNavigate();
  const [file, setFile] = useState([]);
  var auth = useSelector((state) => state.auth);
  const { state: prodState } = location;
  const [show, setShow] = useState(false);
  const [
    uploadFileFetch,
    { data: fileFetch, isLoading, isSuccess, isError, error },
  ] = useUploadFileFetchMutation();

  const [product, setProduct] = useState(
    prodState == undefined
      ? {
          productCatalogId: null,
          name: "",
          dateCreated: new Date(),
          sku: "",
          price: null,
          salePrice: null,
          stockStatus: "",
          productWeight: 0,
          productLength: 0,
          productWidth: 0,
          productHeight: 0,
          note: "",
          adminPrice: null,
          stockQuantity: null,
          productCatalogImage: [],
          description: "",
        }
      : prodState
  );

  async function handleChange(e) {
    var fileList = Array.from(e.target.files);
    var files = [];

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      var formData = new FormData();
      formData.append("files", file);
      await uploadFileFetch(formData).then((filePath) => {
        files.push({
          src: URL.createObjectURL(file),
          pathImage: filePath,
        });
      });
    }

    fileList = fileList.map((f) => URL.createObjectURL(f));

    setFile([...file, ...fileList]);

    setProduct({
      ...product,
      productCatalogImage: [
        ...product.productCatalogImage,
        ...files.map((f) => ({
          productCatalogImageId: product.productCatalogImageId,
          productCatalogId: product.productCatalogId,
          src: f.src,
          pathImage: f.pathImage,
        })),
      ],
    });
    console.log(product);
  }

  const [
    getAllCustomer,
    {
      data: customers,
      isLoading: loadingCustomers,
      isSuccess: loadedCustomers,
      isError: errorCustomersEvent,
      error: errorCustomersText,
    },
  ] = useLazyGetAllCustomerQuery();

  const [
    addProductCatalog,
    {
      isLoading: adding,
      isError: eventErrorAdding,
      isSuccess: added,
      error: textErrorAdding,
    },
  ] = useAddProductCatalogMutation();

  const [
    editProductCatalog,
    {
      isLoading: editing,
      isError: eventErrorEditing,
      isSuccess: edited,
      error: textErrorEditing,
    },
  ] = useEditProductCatalogMutation();

  const handleAddClick = async (product, e) => {
    e.preventDefault();
    await addProductCatalog(product)
      .unwrap()
      .finally(() => {
        navigate("/catalog");
      });
  };

  const handleEditClick = async (product, e) => {
    e.preventDefault();
    await editProductCatalog(product)
      .unwrap()
      .finally(() => {
        navigate("/catalog");
      });
  };

  const searchCustomers = (searchText) => {
    if (searchText == "") getAllCustomer(searchText == "" && "%20");
    if (searchText.length <= 3) return;
    getAllCustomer(searchText);
  };
  console.log(product);
  useEffect(() => {
    if (edited || added) setShow(true);
  }, [editing, adding]);
  return (
    <div className="bg-white">
      <SimpleNotification show={show} setShow={setShow} />
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          {/* Image gallery */}
          <TabGroup className="flex flex-col-reverse">
            {/* Image selector */}
            <div className="mx-auto mt-6 hidden w-full max-w-2xl sm:block lg:max-w-none relative">
              <TabList className="grid grid-cols-4 gap-6">
                {product?.productCatalogImage?.map((image, index) => (
                  <Tab
                    key={index}
                    className="relative h-24 cursor-pointer rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                  >
                    {({ selected }) => (
                      <>
                        <span className="sr-only">{image.name}</span>
                        <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img
                            src={image.src}
                            alt={image.name}
                            className="h-full w-full object-cover object-center"
                          />
                        </span>
                        <Link
                          className="bg-red-900 rounded-md shadow flex justify-center items-center w-8 h-8 cursor-pointer z-50 absolute bottom-1 right-1"
                          onClick={() =>
                            setProduct({
                              ...product,
                              productCatalogImage:
                                product.productCatalogImage.filter(
                                  (im) => im.src !== image.src
                                ),
                            })
                          }
                        >
                          <TrashIcon className="h-6 w-6 text-white" />
                        </Link>
                        <span
                          className={classNames(
                            selected ? "ring-green-500" : "ring-transparent",
                            "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
                ))}
                <Tab
                  key={"add-image"}
                  className="relative h-24 cursor-pointer  rounded-md bg-gray-100 text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                >
                  <span className="absolute inset-0 overflow-hidden rounded-md flex items-center justify-center">
                    <label
                      htmlFor="fileInput"
                      className="relative cursor-pointer "
                    >
                      <span>Aggiungi Immagini</span>
                      <input
                        id="fileInput"
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={handleChange}
                        className="sr-only"
                      />
                    </label>
                  </span>
                </Tab>
              </TabList>
            </div>
            <TabPanels className="aspect-h-1 aspect-w-1 w-full">
              {product?.productCatalogImage?.map((image, index) => (
                <TabPanel key={index}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                  />
                </TabPanel>
              ))}
            </TabPanels>{" "}
          </TabGroup>

          <form
            className="mt-6"
            onSubmit={(e) =>
              (product?.productCatalogId ?? 0) == 0
                ? handleAddClick(product, e)
                : handleEditClick(product, e)
            }
          >
            <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
              <h1 className="">
                <Input
                  type={"text"}
                  label={"Nome Prodotto"}
                  placeholder={"Nome Prodotto"}
                  defaultValue={product?.name ?? ""}
                  onChange={(prodName) =>
                    setProduct({ ...product, name: prodName })
                  }
                />
                <Input
                  type={"text"}
                  label={"SKU"}
                  placeholder={"SKU"}
                  defaultValue={product?.sku ?? ""}
                  onChange={(prodSku) =>
                    setProduct({ ...product, sku: prodSku })
                  }
                />
              </h1>

              <div className="mt-3">
                <h2 className="sr-only">Product information</h2>
                <Input
                  type={"number"}
                  label={"Prezzo Cliente"}
                  placeholder={"Prezzo Cliente"}
                  defaultValue={product?.price ?? ""}
                  onChange={(prodPrice) =>
                    setProduct({ ...product, price: prodPrice })
                  }
                />

                <Input
                  type={"number"}
                  label={"Prezzo Fornitore"}
                  placeholder={"Prezzo Fornitore"}
                  defaultValue={product?.adminPrice ?? ""}
                  onChange={(prodAdminPrice) =>
                    setProduct({ ...product, adminPrice: prodAdminPrice })
                  }
                />

                <Input
                  type={"number"}
                  label={"Peso"}
                  placeholder={"Peso"}
                  defaultValue={product?.productWeight ?? ""}
                  onChange={(productWeight) =>
                    setProduct({ ...product, productWeight })
                  }
                />
              </div>

              <div className="mt-3">
                <Input
                  type={"number"}
                  min={-1}
                  step={1}
                  label={"Q.tà Magazzino"}
                  placeholder={"Q.tà Magazzino"}
                  defaultValue={product?.stockQuantity ?? ""}
                  onChange={(prodStockQuantity) =>
                    setProduct({ ...product, stockQuantity: prodStockQuantity })
                  }
                />
              </div>

              <div className="mt-6">
                <h3 className="sr-only">Description</h3>

                <TextArea
                  label={"Descrizione"}
                  placeholder={"Descrizione prodotto"}
                  defaultValue={product?.description ?? ""}
                  onChange={(prodDescription) =>
                    setProduct({ ...product, description: prodDescription })
                  }
                />
              </div>
              <div className="flex gap-2 items-center bg-gray-300 rounded-md mt-4">
                <Checkbox
                  checked={product?.visible ?? false}
                  onChange={(e) =>
                    setProduct({ ...product, visible: e.target.checked })
                  }
                />
                <span className="font-bold text-gray-700">Visibile</span>
              </div>
              <div className="mt-10">
                <button
                  disabled={adding || editing}
                  type="submit"
                  className="flex w-full flex-1 items-center justify-center rounded-md border border-transparent bg-green-600 px-8 py-3 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:w-full"
                >
                  {(product?.productCatalogId ?? 0) == 0 ? "Aggiungi" : "Salva"}
                </button>
              </div>
            </div>
          </form>
        </div>
        {/* {auth.roles.includes(1) && (
          <div className="">
            <SearchBar
              placeholder={"Cerca cliente per nome, store o email..."}
              onChange={(search) => {
                searchCustomers(search);
              }}
            />
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              {customers?.map((cus, index) => (
                <div
                  key={index}
                  className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
                >
                  <div className="min-w-0 flex-1">
                    <a href="#" className="focus:outline-none">
                      <span className="absolute inset-0" aria-hidden="true" />
                      <p className="text-sm font-medium text-gray-900">
                        {cus.name} {cus.surname}
                      </p>
                      <p className="truncate text-sm text-gray-500">
                        {cus.email}
                      </p>
                    </a>
                  </div>
                  <div>
                    <InputToggleEdit hintTextDisable={"Inserisci Prezzo"} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
}
