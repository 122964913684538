import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronDownIcon,
  XCircleIcon,
} from "@heroicons/react/solid";
import { XIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DropDown({ items, selectedItem, onChange, label }) {
  return (
    <Listbox value={selectedItem} onChange={(e) => onChange(e)}>
      {({ open }) => (
        <>
          <div className="relative w-fit ">
            {" "}
            {label && (
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
                {label}
              </Listbox.Label>
            )}
            <Listbox.Button
              className={`relative w-full cursor-default rounded-md ${
                (selectedItem?.id ?? 0) !== 0
                  ? "bg-green-500 ring-green-600 text-white font-bold"
                  : "bg-white ring-gray-300 text-gray-900"
              } py-1.5 pl-3 pr-10 text-left  shadow-sm ring-2 ring-inset  focus:outline-none focus:ring-2 focus:ring-green-600 sm:text-sm sm:leading-6`}
            >
              <span className="block truncate">{selectedItem?.name}</span>
              <span
                className={` absolute inset-y-0 right-0 flex items-center pr-2 ${
                  (selectedItem?.id ?? 0) !== 0
                    ? "cursor-pointer"
                    : "pointer-events-none"
                }`}
                onClick={
                  selectedItem?.id !== 0 ? () => onChange(items[0]) : undefined
                }
              >
                {(selectedItem?.id ?? 0) !== 0 ? (
                  <XCircleIcon className="h-5 w-5 text-white" />
                ) : (
                  <ChevronDownIcon
                    className={`h-5 w-5 text-gray-400`}
                    aria-hidden="true"
                  />
                )}
              </span>
            </Listbox.Button>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-fit overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {items?.map((item) => (
                  <Listbox.Option
                    key={item.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-green-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-3 pr-9"
                      )
                    }
                    value={item}
                  >
                    {({ selectedItem, active }) => (
                      <>
                        <span
                          className={classNames(
                            selectedItem ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {item?.name}
                        </span>

                        {selectedItem ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-green-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

export default DropDown;
