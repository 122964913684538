import React from "react";

function BadgeErrorMessage({ message, isError }) {
  return (
    isError && (
      <div className="text-center">
        <span
          className="mt-2 inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700"
          id="payment-message"
        >
          <svg
            className="h-1.5 w-1.5 fill-red-500"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx={3} cy={3} r={3} />
          </svg>
          {message}
        </span>
      </div>
    )
  );
}

export default BadgeErrorMessage;
