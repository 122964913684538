export function download_pdf(pdf, fileName) {
  if (pdf != "") {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");

    downloadLink.href = linkSource;
    downloadLink.download = `${fileName}`;
    downloadLink.click();
  }
}
