import { createContext, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { useLocation } from "react-router-dom";

const NavigationContext = createContext();

export const NavigationProvider = ({ children }) => {
  const [navigation, setNavigation] = useState([
    { name: "Home", href: "/" },
    { name: "Login", href: "/login" },
    { name: "Registrati", href: "/register" },
    { name: "Integrazione", href: "/integration" },
    { name: "Ordini", href: "/orders" },
    { name: "Catalogo", href: "/catalog" },
  ]);
  // useEffect(() => {
  //   var newNav = navigation;
  //   newNav.map((nav) => {
  //     nav.current = false;

  //     return nav;
  //   });
  //   setNavigation(newNav);
  // }, [location.pathname]);

  return (
    <NavigationContext.Provider value={{ navigation, setNavigation }}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
