import moment from "moment";
import React, { useRef, useState } from "react";
import { InputField } from "../components/RegistrationForm";
import ListTable from "../components/custom/ListTable";
import HeaderTable from "../components/custom/HeaderTable";
import RowTable from "../components/custom/RowTable";
import HeaderColTable from "../components/custom/HeaderColTable";
import HiddenHeaderColTable from "../components/custom/HiddenHeaderColTable";
import BodyTable from "../components/custom/BodyTable";
import CellRowTable from "../components/custom/CellRowTable";
import ButtonTable from "../components/custom/ButtonTable";
import { useSelector } from "react-redux";
import TextCopyToClipboard from "../components/catalog/TextCopyToClipboard";
import { PencilIcon, SearchIcon, TrashIcon } from "@heroicons/react/outline";
import { TableLoading } from "../components/shipment/LoadingTable";
import {
  useDeleteShipmentReturnToSenderMutation,
  useGetAllShipmentsReturnToSenderQuery,
  useInsertShipmentReturnToSenderByTrackingMutation,
  useUpdateShipmentReturnToSenderMutation,
} from "../services/shipmentReturnToSenderApi";
import DropDown from "../components/order/NewShopifyOrderList/DropDown";
import DropDownCustomer from "../components/DropDownCustomer";
import DropDownStore from "../components/DropDownStore";
import ButtonBasic from "../components/ButtonBasic";
import ToggleButton from "../components/custom/ToggleButton";
import ProductsOrderTable from "../components/order/NewShopifyOrderList/ProductsOrderTable";

const options = [
  { id: 0, name: "Tutto" },
  { id: 1, name: "Si" },
  { id: 2, name: "No" },
];

function ReturnsToSenderPage() {
  const [showOrderProducts, setShowOrderProducts] = useState(false);
  const [barcode, setBarcode] = useState("");
  const auth = useSelector((state) => state.auth);
  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState({
    dateRange: {
      startDate: moment().add(-30, "days").format("yyyy-MM-DD"),
      endDate: moment().format("yyyy-MM-DD"),
    },
    isPaidOrder: options[0],
  });

  const {
    data: shipmentsReturnToSender,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetAllShipmentsReturnToSenderQuery({
    page: 1,
    rowsPage: 20,
    filter,
    searchText,
  });

  const [insertShipmentReturnToSenderByTracking] =
    useInsertShipmentReturnToSenderByTrackingMutation();

  const [updateShipmentReturnToSender] =
    useUpdateShipmentReturnToSenderMutation();

  const [deleteShipmentReturnToSender] =
    useDeleteShipmentReturnToSenderMutation();

  return (
    <main className="px-4">
      <section
        id="filter-shipments-return-to-sender"
        className="filter shipments-return-to-sender"
      >
        <div className="flex flex-wrap gap-2 items-end mb-2">
          <div className="flex gap-2 mt-2 flex-wrap items-end">
            <div>
              <DropDown
                label={"Pagati"}
                items={options}
                selectedItem={filter.isPaidOrder}
                onChange={(value) =>
                  setFilter({ ...filter, isPaidOrder: value })
                }
              />
            </div>
            <DropDownStore
              onChange={(store) =>
                setFilter({ ...filter, shopifyStoreId: store?.value?.id })
              }
            />
            <DropDownCustomer
              onChange={(customer) =>
                setFilter({ ...filter, codfacileCustomer: customer?.value?.id })
              }
            />

            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Data Inizio
              </label>
              <input
                className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                type="date"
                value={filter.dateRange.startDate}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    dateRange: {
                      startDate: e.target.value,
                      endDate: filter.dateRange.endDate,
                    },
                  })
                }
              />
            </div>
            <div>
              <label className="block text-sm font-medium leading-6 text-gray-900">
                Data Fine
              </label>
              <input
                className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                type="date"
                value={filter.dateRange.endDate}
                onChange={(e) =>
                  setFilter({
                    ...filter,
                    dateRange: {
                      endDate: e.target.value,
                      startDate: filter.dateRange.startDate,
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="flex items-center gap-2 flex-1">
            <input
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") refetch();
              }}
              type="text"
              name="name"
              id="name"
              className="block w-full rounded-xl border-0 px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
              placeholder="Cerca nelle spedizioni"
            />
            <button
              onClick={(e) => refetch()}
              type="button"
              className="rounded-xl bg-green-600 p-2 text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600
           bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium text-sm  text-center "
            >
              <SearchIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </section>
      <InputField
        value={barcode}
        onChange={(e) => setBarcode(e.target.value)}
        onEnter={() => {
          insertShipmentReturnToSenderByTracking(barcode);
          setBarcode("");
        }}
        autofocus={true}
        type="text"
        label={"Spara Codice A Barre"}
      />
      <div className="mt-2">
        <ToggleButton
          enabled={showOrderProducts}
          onChangeEvent={(e) => {
            setShowOrderProducts(e);
          }}
          text={"Mostra Prodotti nell'ordine"}
        />
      </div>
      {isLoading ? (
        <TableLoading />
      ) : isSuccess ? (
        <ListTable className="mt-2">
          <HeaderTable>
            <RowTable>
              <HeaderColTable>Data</HeaderColTable>
              <HeaderColTable>Cliente CODFacile</HeaderColTable>
              <HeaderColTable>Tracking</HeaderColTable>
              <HeaderColTable>Ordine</HeaderColTable>
              <HeaderColTable>Destinatario</HeaderColTable>
              <HeaderColTable>COD</HeaderColTable>
              <HiddenHeaderColTable />
            </RowTable>
          </HeaderTable>
          <BodyTable>
            {shipmentsReturnToSender?.map((ship, index) => (
              <RowTable key={index}>
                <CellRowTable>{ship.created_date}</CellRowTable>
                <CellRowTable>{ship.codfacile_customer}</CellRowTable>

                <CellRowTable>
                  <TextCopyToClipboard
                    text={ship.tracking_number}
                    textHover={"COPIA TRACKING"}
                  />
                  <br />
                  <ProductsOrderTable
                    orderId={ship.order_id}
                    showOrderProducts={showOrderProducts}
                  />
                </CellRowTable>
                <CellRowTable>
                  {ship.alias_store == "" ? ship.store_name : ship.alias_store}
                  <br />
                  {ship.order_number}
                </CellRowTable>

                <CellRowTable>
                  {ship.customer}
                  <br /> {ship.shipping_address}
                </CellRowTable>
                <CellRowTable>
                  {ship.financial_status == "paid" ? "" : ship.order_price}
                </CellRowTable>
                <CellRowTable>
                  <div className="flex gap-3 justify-end">
                    {!ship.is_confirmed ? (
                      <ButtonTable
                        Icon={TrashIcon}
                        color={
                          "from-red-400 via-red-500 to-red-600 focus:ring-red-300 dark:focus:ring-red-800"
                        }
                        onClick={() => {
                          deleteShipmentReturnToSender(ship.id);
                        }}
                      />
                    ) : (
                      <></>
                    )}

                    <ButtonBasic
                      onClick={() => {
                        updateShipmentReturnToSender({
                          shipmentId: ship.id,
                          isConfirmed: true,
                        });
                      }}
                      textButton={ship.is_confirmed ? "CONFERMATO" : "CONFERMA"}
                      disabled={ship.is_confirmed}
                    />
                  </div>
                </CellRowTable>
              </RowTable>
            ))}
          </BodyTable>
        </ListTable>
      ) : (
        <></>
      )}
    </main>
  );
}

export default ReturnsToSenderPage;
