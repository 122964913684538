export const HandlingStatusEnumRadioButton = {
  "In Lavorazione": "In Lavorazione",
  "Svincolo Non Possibile": "Svincolo Non Possibile",
  "Svincolo Effettuato": "Svincolo Effettuato",
  "Da Lavorare": "Da Lavorare",
};

export const HandlingStatusEnum = {
  "In Lavorazione": 0,
  "Svincolo Non Possibile": 1,
  "Svincolo Effettuato": 2,
  "Da Lavorare": 3,
};

export const HandlingStatusRadioButtons = [
  { id: 0, name: HandlingStatusEnumRadioButton["In Lavorazione"] },
  {
    id: 1,
    name: HandlingStatusEnumRadioButton["Svincolo Non Possibile"],
  },
  { id: 2, name: HandlingStatusEnumRadioButton["Svincolo Effettuato"] },
  { id: 3, name: HandlingStatusEnumRadioButton["Da Lavorare"] },
];
