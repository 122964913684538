import React, { useEffect, useState } from "react";
import { useInsertWireTransferMutation } from "../../services/wireTransferApi";
import { CheckIcon } from "@heroicons/react/outline";

import { useGetAmountToBePaidQuery } from "../../services/remittanceApi";
import BadgeErrorMessage from "../custom/BadgeErrorMessage";
import BadgeSuccessMessage from "../custom/BadgeSuccessMessage";
function FormAddWireTransfer({ userId }) {
  const {
    data: amountToBePaid,
    isLoading: amountTaking,
    isSuccess: amountTaken,
    isError: amountTakeError,
    error: amountError,
  } = useGetAmountToBePaidQuery(
    { userId },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [wireTransfer, setWireTransfer] = useState({
    amount: amountToBePaid,
    userId,
  });

  const [insertWireTransfer, { isLoading, isSuccess, isError }] =
    useInsertWireTransferMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (wireTransfer.amount > 0) {
      await insertWireTransfer({ wireTransfer });
    }
  };

  useEffect(() => {
    if (isError || isSuccess) setWireTransfer({ ...wireTransfer, amount: 0 });
  }, [isLoading]);

  useEffect(() => {
    if (amountTaken)
      setWireTransfer({
        ...wireTransfer,
        amount: amountToBePaid?.toFixed(2) ?? 0,
      });
  }, [amountTaking]);

  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
      <form className="space-y-6" onSubmit={(e) => handleSubmit(e)}>
        <div>
          {" "}
          <label
            htmlFor="importo"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Importo
          </label>
          <input
            value={wireTransfer.amount}
            type="number"
            max={amountToBePaid?.toFixed(2) ?? 0}
            min={amountToBePaid?.toFixed(2) ?? 0}
            onChange={(e) =>
              setWireTransfer({ ...wireTransfer, amount: e.target.value })
            }
            //id={val}
            name="importo"
            autoComplete="current-password"
            required
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-facile sm:text-sm sm:leading-6"
          />
        </div>
        <span className="text-md font-semibold ">
          Saldo da pagare:{" "}
          {amountTaking ? (
            "Calcolo rimesse da saldare..."
          ) : amountTaken ? (
            `${amountToBePaid?.toFixed(2) ?? 0}€`
          ) : amountTakeError ? (
            "Errore nel calcolo del saldo"
          ) : (
            <></>
          )}
        </span>
        <div>
          <button
            disabled={isLoading}
            type="submit"
            className="flex w-full justify-center items-center gap-2 rounded-md bg-green-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500
            disabled:bg-green-300 disabled:focus-visible:outline-green-300"
          >
            Conferma <CheckIcon className="w-5 h-5" />
          </button>
          {isError && (
            <BadgeErrorMessage
              isError={isError}
              message={"Errore nell'inserimento del bonifico."}
            />
          )}
          {isSuccess && (
            <BadgeSuccessMessage
              isSuccess={isSuccess}
              message={"Bonifico andato a buon fine."}
            />
          )}
        </div>
      </form>
    </div>
  );
}

export default FormAddWireTransfer;
