import {
  CheckIcon,
  CreditCardIcon,
  DownloadIcon,
  PrinterIcon,
} from "@heroicons/react/outline";
import React, { useState } from "react";
import { DownloadPdfOrder } from "../DownloadPdfOrder";
import {
  useGetProductsOrderTableQuery,
  useLazyGetOneOrderByIdQuery,
} from "../../../services/orderApi";
import { LoadingIcon } from "../../custom/LoadingIcon";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { imagesUrl } from "../../../config/baseUrl";
import UpdateOrderTagsButtons from "../../UpdateOrderTagsButtons";
//var moment = require("moment-timezone");

function RowShopifyOrderToConfirm({
  showOrderProducts,
  order,
  selectedOrder,
  printingMode,
  handleAddItem,
  handleItemToPrint,
  selectedOrderToPrint,
  auth,
  onClickPay,
}) {
  const [orderState, setOrderState] = useState(order);

  const {
    data: productsOrder,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetProductsOrderTableQuery(
    { orderId: order.id },
    { skip: !showOrderProducts }
  );

  const [
    getOneOrderById,
    {
      data: orderSingle,
      isLoading: loadingOrder,
      isSuccess: loadedOrder,
      isError: errorOrderEvent,
      error: errorOrderText,
    },
  ] = useLazyGetOneOrderByIdQuery();

  return (
    <>
      <tr
        //onClick={() => navigate(`/orders/${order.id}`)}
        key={orderState.id}
        className={`shopify-order-row even:bg-gray-50 ${
          orderState.financial_status == "pending" && orderState.price == 0
            ? "bg-red-100"
            : ""
        }`}
      >
        <Tooltip
          title={orderState.error_generating_shipment
            .replace(
              'Client error: `POST https://rdf.spedisci.online/api/v2/shipping/create` resulted in a `400 Bad Request` response:\n{"error":"',
              ""
            )
            .replace("pi\\u00f9", "più")
            .replace('"}', "")
            .replace(" (truncated...)", "")
            .replaceAll("\\", "")}
        >
          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
            {orderState.error_generating_shipment !== "" ? (
              <span className="relative flex h-3 w-3">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
              </span>
            ) : (
              ""
            )}
          </td>
        </Tooltip>
        <td
          onClick={() =>
            handleAddItem(!selectedOrder.includes(orderState), orderState)
          }
          className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 cursor-pointer hover:bg-gray-100"
        >
          {(orderState?.tracking_number ?? "") == "" &&
          (orderState?.fullfilment_status ?? "") == "" ? (
            <input
              checked={selectedOrder.includes(orderState)}
              onChange={(e) => handleAddItem(e.target.checked, orderState)}
              id={`print-${orderState.id}`}
              aria-describedby="comments-description"
              name={`print-${orderState.id}`}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-green-facile focus:ring-green-facile"
              disabled={
                (orderState?.tracking_number ?? "") !== ""
                  ? true
                  : (orderState?.fullfilment_status ?? "") !== ""
                  ? true
                  : false
              }
            />
          ) : printingMode ? (
            <div className="flex flex-col items-center gap-1">
              <PrinterIcon className="h-4 w-4" />
              <input
                checked={selectedOrderToPrint.includes(orderState)}
                onChange={(e) => handleItemToPrint(e, orderState)}
                id={`print-${orderState.id}`}
                aria-describedby="comments-description"
                name={`print-${orderState.id}`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-green-facile focus:ring-green-facile"
              />
            </div>
          ) : (
            <></>
          )}
        </td>
        <td className="relative py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-3 flex items-center gap-1">
          {auth.roles.find((role) => role == 1) ? (
            <DownloadPdfOrder
              order={orderState}
              Icon={DownloadIcon}
              style={`h-4 w-4 text-gray-400 `}
            />
          ) : (
            <></>
          )}
        </td>
        <td className="cursor-pointer py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
          {` ${moment(orderState.created_date).format("DD/MM")}`}
          <br />
          {`${moment(orderState.created_date).format("HH:mm")}`}
        </td>
        <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
          <div className="flex flex-col">
            <a
              className="cursor-pointer"
              target="_blank"
              href={`/orders/${orderState.id}`}
            >
              <div className="flex gap-3 items-center mb-0.5 w-max">
                <span>{orderState.order_number}</span>
              </div>
              <div>
                {orderState?.tags == "" ? (
                  ""
                ) : (
                  <>
                    {orderState.tags.split(",").map((tag) => (
                      <span
                        key={tag}
                        className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mr-2"
                      >
                        {tag}
                      </span>
                    ))}
                    <br />
                  </>
                )}
                <span className="text-xs font-medium">
                  {(orderState?.alias_store ?? "") == ""
                    ? orderState.store_name
                    : orderState.alias_store}
                </span>
              </div>
            </a>
            <div>
              {isLoading ? (
                <LoadingIcon className="h-4 w-4" />
              ) : isSuccess ? (
                // <table className="min-w-full divide-y divide-gray-300">
                <table className="min-w-full border divide-y rounded-md">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-1.5 pl-2 pr-1 text-left text-sm font-semibold text-gray-900 sm:pl-1"
                      >
                        nome
                      </th>
                      {/* <th
                        scope="col"
                        className="px-1 py-1.5 text-left text-sm font-semibold text-gray-900"
                      >
                        sku
                      </th> */}
                      <th
                        scope="col"
                        className="px-1 py-1.5 text-left text-sm font-semibold text-gray-900"
                      >
                        q.tà
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {productsOrder?.map((prod, index) => (
                      <tr key={`${prod.name}-${index}`}>
                        <td className="py-2 pl-2 pr-1 text-sm font-medium text-gray-900 sm:pl-1 flex gap-2 items-center">
                          {prod.image == "" ? (
                            <></>
                          ) : (
                            <img width={70} src={`${imagesUrl}${prod.image}`} />
                          )}
                          {prod.name}
                        </td>
                        {/* <td className="px-1 py-2 text-sm text-gray-500">
                          <TextCopyToClipboard
                            text={prod.sku}
                            textHover={"COPIA SKU"}
                          />
                        </td> */}
                        <td className="px-1 py-2 text-sm text-gray-500">
                          {prod.quantity}
                        </td>
                      </tr>
                    )) ?? <></>}
                  </tbody>
                </table>
              ) : (
                <></>
              )}
            </div>
          </div>
        </td>
        {/* <td className="px-3 py-4 text-sm text-gray-500">
          <span className="flex gap-2">
            {order.fulfillment_status_name != "" &&
              (order.fulfillment_status_id ==
              FulfillmentStatus.InLavorazione ? (
                <BadgeComponent
                  background={"yellow"}
                  color={"gray"}
                  text={order?.fulfillment_status_name ?? ""}
                />
              ) : order.fulfillment_status_id ==
                FulfillmentStatus.ImpossibileDaEvadere ? (
                <BadgeComponent
                  background={"red"}
                  color={"gray"}
                  text={order?.fulfillment_status_name ?? ""}
                />
              ) : order.fulfillment_status_id ==
                FulfillmentStatus.OrdineEvaso ? (
                <BadgeComponent
                  background={"green"}
                  color={"gray"}
                  text={order?.fulfillment_status_name ?? ""}
                />
              ) : (
                <></>
              ))}
            <PencilAltIcon
              className="h-6 w-6 cursor-pointer"
              onClick={() => onEditFulfillmentRequest(order)}
            />
          </span>
          {order.fullfilment_status != "" && (
            <span
              className={`inline-flex items-center gap-x-0.5 rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ${
                order.fullfilment_status == "Completato"
                  ? "bg-green-50 text-green-700 ring-green-600/10"
                  : "bg-orange-50 text-orange-700 ring-orange-600/10"
              } `}
            >
              {order.fullfilment_status}
            </span>
          )}
        </td> */}
        <td className="px-3 py-4 text-sm text-gray-500">
          {orderState.customer}
          <br />
          <span className="font-bold">{orderState.shipping_address}</span>
          <br />
          <span className="font-bold">{orderState?.shipping_phone ?? ""}</span>
        </td>
        {auth.roles.find((role) => role == 1) ? (
          <td className="px-3 py-4 text-sm text-gray-500">
            {orderState.codfacile_customer}
          </td>
        ) : (
          <></>
        )}

        {/* <td className="px-3 py-4 text-sm text-gray-500">
          {(order?.status ?? "") != "" && (
            <span
              className={`inline-flex items-center gap-x-1.5 rounded-full  px-2 py-1 text-xs font-medium ${
                order.status == "Spedizione non trovata"
                  ? "text-red-700 bg-red-100"
                  : order.status == "CONSEGNATA"
                  ? "text-green-700 bg-green-100"
                  : "text-yellow-800 bg-yellow-100"
              }`}
            >
              <svg
                className={`h-1.5 w-1.5 ${
                  order.status == "Spedizione non trovata"
                    ? "fill-red-500"
                    : order.status == "CONSEGNATA"
                    ? "fill-green-500"
                    : "fill-yellow-500"
                } `}
                viewBox="0 0 6 6"
                aria-hidden="true"
              >
                <circle cx={3} cy={3} r={3} />
              </svg>
              {order.status}
            </span>
          )}

          <br />
          {order.tracking_number == undefined ? (
            <></>
          ) : (
            <TrackingButton trackingNumber={order.tracking_number} />
          )}
        </td> */}
        <td className={`px-3 py-4 text-sm `}>
          {orderState.financial_status === "pending" && (
            <span
              className={`${
                orderState.remittance_loaded
                  ? "text-white bg-green-600 rounded-md py-1 px-2 font-bold"
                  : "text-gray-500"
              }`}
            >
              {orderState.order_price}
            </span>
          )}
        </td>
        {/* <td className="px-3 py-4 text-sm text-gray-500">
          {order.products_cost}
        </td>
        <td className="px-3 py-4 text-sm text-gray-500">
          {order.shipping_cost}
        </td> */}
        <td className="px-3 py-4 text-sm text-gray-500 text-center">
          {orderState.financial_status != "pending" ? (
            <button
              disabled={(orderState?.amount_paid ?? 0) !== 0}
              onClick={() => onClickPay(orderState)}
              type="button"
              className="inline-flex items-center gap-x-1.5 rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100"
            >
              {(orderState?.amount_paid ?? 0) == 0 ? (
                <>
                  <CreditCardIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  PAGA
                </>
              ) : (
                <>
                  <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  PAGATO {orderState.amount_paid}€
                </>
              )}
            </button>
          ) : (
            <button
              type="button"
              className=" rounded bg-orange-50 px-2 py-1 text-xs font-semibold text-orange-600 shadow-sm hover:bg-orange-100 cursor-default"
            >
              COD
            </button>
          )}
        </td>
        <td className="px-3 py-4 text-sm text-gray-500 text-center ">
          <UpdateOrderTagsButtons
            onClick={(statusTag) => {
              setTimeout(function () {
                getOneOrderById(orderState.id).then((res) => {
                  if (res.error) {
                    return;
                  }
                  setOrderState(res.data);
                });
              }, 5000);
            }}
            orderId={orderState.id}
            //orderTags={orderState?.tags?.split(",") ?? []}
          />
        </td>
      </tr>
      <tr>
        {(orderState.order_note?.trim() ?? "") == "" ? (
          <></>
        ) : (
          <>
            <td
              className="px-3 py-2 text-sm font-medium text-gray-900 uppercase text-right"
              colSpan="3"
            >
              nota:
            </td>
            <td className="pr-3 py-2 text-sm text-gray-500" colSpan="15">
              {orderState.order_note}
            </td>
          </>
        )}
      </tr>
    </>
  );
}

export default RowShopifyOrderToConfirm;
