import { useSelector } from "react-redux";
import SubscriptionsPlan from "../components/pricing/SubscriptionsPlan";
import {
  useGetAllPromotionQuery,
  useGetAllReferralQuery,
} from "../services/promotionApi";
import { TableLoading } from "../components/shipment/LoadingTable";
import ListTable from "../components/custom/ListTable";
import HeaderTable from "../components/custom/HeaderTable";
import HeaderColTable from "../components/custom/HeaderColTable";
import BodyTable from "../components/custom/BodyTable";
import RowTable from "../components/custom/RowTable";
import CellRowTable from "../components/custom/CellRowTable";
import { useEffect, useState } from "react";
import { LoadingIcon } from "../components/custom/LoadingIcon";
import TextCopyToClipboard from "../components/catalog/TextCopyToClipboard";
import moment from "moment";

export const AccountPage = () => {
  const [referrals, setReferrals] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const auth = useSelector((state) => state.auth);
  const {
    data: dataReferrals,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllReferralQuery();

  const {
    data: dataPromotions,
    isLoading: loadingPromotions,
    isSuccess: successPromotions,
    isError: errorPromotionsEvent,
    error: errorPromotionsText,
  } = useGetAllPromotionQuery(undefined, {
    skip: auth.roles.filter((r) => r == 1 || r == 3).length > 0,
  });

  useEffect(() => {
    if (isSuccess) {
      setReferrals(dataReferrals);
      //console.log("referrals", referrals);
    }
  }, [isLoading]);

  useEffect(() => {
    if (successPromotions) {
      setPromotions(dataPromotions);
      console.log("promotions", dataPromotions);
    }
  }, [loadingPromotions]);

  return (
    <main>
      <SubscriptionsPlan />
      <section className="mx-auto max-w-7xl px-6 lg:px-8 mt-2">
        <h2 className="text-xl font-bold text-gray-700 ">{`Referral Attivi (${
          referrals?.length ?? 0
        }) - ${referrals
          .reduce((accumulator, ref) => {
            return accumulator + ref.fee;
          }, 0)
          .toFixed(2)}€`}</h2>
        {loadingPromotions ? (
          <LoadingIcon color={"text-black"} />
        ) : successPromotions ? (
          <ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 mb-2"
          >
            {promotions
              ?.filter((pr) => pr.active == true)
              ?.map((promo, index) => (
                <li
                  key={index}
                  className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
                >
                  <div className="flex w-full items-center justify-between space-x-6 p-6">
                    <div className="flex-1 truncate">
                      <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-medium text-gray-900">
                          {promo.aliasCode}
                        </h3>
                        <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          {promo.active ? "ATTIVO" : "NON ATTIVO"}
                        </span>
                      </div>
                      <p className="mt-1 truncate text-sm text-gray-500">
                        <TextCopyToClipboard
                          text={`https://app.codfacile.com/register/${promo.aliasCode}`}
                          textHover={"COPIA REFERRAL"}
                        />
                      </p>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        ) : (
          <></>
        )}
        {isLoading ? (
          <TableLoading />
        ) : isSuccess ? (
          <ListTable>
            <HeaderTable>
              <RowTable>
                <HeaderColTable>Data Rinnovo</HeaderColTable>
                <HeaderColTable>Nome</HeaderColTable>
                <HeaderColTable>Cognome</HeaderColTable>
                <HeaderColTable>Codice Promo</HeaderColTable>
                <HeaderColTable>€ Abbonamento</HeaderColTable>
                <HeaderColTable>Commissioni</HeaderColTable>
              </RowTable>
            </HeaderTable>
            <BodyTable>
              {referrals?.map((ref, index) => (
                <RowTable key={index}>
                  <CellRowTable>
                    {moment(ref.created_date).format("DD-MM-YYYY")}
                  </CellRowTable>
                  <CellRowTable>{ref.name}</CellRowTable>
                  <CellRowTable>{ref.surname}</CellRowTable>
                  <CellRowTable>{ref.promo_code}</CellRowTable>
                  <CellRowTable>{ref.amount}</CellRowTable>
                  <CellRowTable>
                    {(ref?.fee ?? 0) < 0 ? 0 : ref.fee}
                  </CellRowTable>
                </RowTable>
              ))}
            </BodyTable>
          </ListTable>
        ) : (
          <></>
        )}
      </section>
    </main>
  );
};
