import React, { useEffect, useState } from "react";
import PopupDialog from "./custom/PopupDialog";
import { useGetCustomerProductsCatalogMutation } from "../services/catalogApi";
import SearchBar from "./catalog/SearchBar";
import { LoadingIcon } from "./custom/LoadingIcon";
import InputToggleEdit from "./catalog/InputToggleEdit";
import { CurrencyEuroIcon } from "@heroicons/react/outline";
import CardMiniProductSetPrice from "./CardMiniProductSetPrice";

function PopupSelectionCustomerProducts({ open, setOpen, companyId }) {
  const [
    getCustomerProductsCatalog,
    { data: prods, isLoading, isSuccess, isError, error },
  ] = useGetCustomerProductsCatalogMutation();

  const searchProducts = (searchText) => {
    if (searchText == "") getCustomerProductsCatalog({ searchText, companyId });
    if (searchText.length <= 2) return;
    getCustomerProductsCatalog({ searchText, companyId });
  };

  useEffect(() => {
    getCustomerProductsCatalog({ searchText: "", companyId });
  }, []);

  return (
    <PopupDialog open={open} setOpen={setOpen}>
      <div className="sticky top-0 z-50">
        <SearchBar
          placeholder={"Cerca i prodotti per nome o sku..."}
          onChange={searchProducts}
        />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 overflow-y-auto">
        {prods?.map((prod, index) => (
          <CardMiniProductSetPrice
            key={index}
            prod={prod}
            companyId={companyId}
          />
        ))}
      </div>
    </PopupDialog>
  );
}

export default PopupSelectionCustomerProducts;
