import {
  EyeIcon,
  LinkIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { useGetShopifyKeysQuery } from "../services/userApi";
import ModalMessage from "./ModalMessage";
import { useState } from "react";
import ListTable from "./custom/ListTable";
import HeaderTable from "./custom/HeaderTable";
import HiddenHeaderColTable from "./custom/HiddenHeaderColTable";
import BodyTable from "./custom/BodyTable";
import HeaderColTable from "./custom/HeaderColTable";
import RowTable from "./custom/RowTable";
import CellRowTable from "./custom/CellRowTable";
import ButtonTable from "./custom/ButtonTable";
import {
  useCreateWebhooksMutation,
  useLazyGetAllWebhooksQuery,
} from "../services/shopifyApi";
import SimpleNotification from "./SimpleNotification";
import { useSelector } from "react-redux";
import PopupDialog from "./custom/PopupDialog";
import { LoadingIcon } from "./custom/LoadingIcon";
import SimpleCard from "./custom/SimpleCard";

export default function ShopifyKeyList({ handleDoubleClickRow }) {
  const [isSuccessNotification, setIsSuccessNotification] = useState(true);
  const [showNotification, setShowNotification] = useState(false);
  const [messageNotification, setMessageNotification] = useState("");
  const [rowToDelete, setRowToDelete] = useState({});
  const [clickedRow, setClickedRow] = useState({});
  const [showListWebhooks, setShowListWebhooks] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const auth = useSelector((state) => state.auth);
  const {
    data: keys,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetShopifyKeysQuery("getKeys");

  const [createWebhooks, { isLoading: loadingCreateWebhooks }] =
    useCreateWebhooksMutation();
  const [
    getAllWebhooks,
    {
      data: webhooks,
      isLoading: loadingWebhooks,
      isSuccess: successWebhooks,
      isError: errorWebhooksEvent,
      error: errorWebhooksText,
    },
  ] = useLazyGetAllWebhooksQuery();

  const onCreateWebhooksClick = async (key) => {
    setClickedRow(key);
    await createWebhooks({ key }).then((res) => {
      if (res.error) {
        setMessageNotification(res.error.data.message);
        setIsSuccessNotification(false);
        setShowNotification(true);
        return;
      }
      setMessageNotification(res.data.message);
      setIsSuccessNotification(true);
      setShowNotification(true);
    });
    setClickedRow({});
  };

  const onGetWebhooksClick = async (key) => {
    setClickedRow(key);

    await getAllWebhooks(key.storeName).then((res) => {
      if (res.error) {
        setMessageNotification(res.error.data.message);
        setIsSuccessNotification(false);
        setShowNotification(true);
        return;
      }
      setShowListWebhooks(true);
    });
    setClickedRow({});
  };

  return (
    <>
      <SimpleNotification
        show={showNotification}
        setShow={setShowNotification}
        message={messageNotification}
        success={isSuccessNotification}
      />
      <PopupDialog open={showListWebhooks} setOpen={setShowListWebhooks}>
        <h2 className="mb-2">Webhooks</h2>
        {loadingWebhooks ? (
          <LoadingIcon color={"text-gray-900"} />
        ) : successWebhooks ? (
          <ul className="flex gap-2 flex-col">
            {webhooks?.map((webhook, index) => (
              <SimpleCard
                key={index}
                title={webhook.address}
                subtitle={webhook.topic}
              />
            ))}
          </ul>
        ) : (
          <></>
        )}
      </PopupDialog>
      <ListTable className="mt-2">
        <HeaderTable>
          <RowTable>
            {auth.roles.includes(1) && <HeaderColTable>Cliente</HeaderColTable>}
            {auth.roles.includes(1) && (
              <HeaderColTable>Telefono</HeaderColTable>
            )}
            <HeaderColTable>Alias</HeaderColTable>
            <HeaderColTable>Store</HeaderColTable>
            <HeaderColTable>Access Token</HeaderColTable>
            <HeaderColTable>Chiave Api</HeaderColTable>
            <HeaderColTable>Chiave Api Segreta</HeaderColTable>
            <HiddenHeaderColTable />
            {auth.roles.includes(1) && <HiddenHeaderColTable />}
          </RowTable>
        </HeaderTable>
        <BodyTable>
          {keys?.map((key, index) => (
            <RowTable key={index}>
              {auth.roles.includes(1) && (
                <CellRowTable>{`${key.registeredUser.name} ${key.registeredUser.surname}`}</CellRowTable>
              )}
              {auth.roles.includes(1) && (
                <CellRowTable>
                  {key?.registeredUser?.company?.phone ?? ""}
                </CellRowTable>
              )}

              <CellRowTable>{key.aliasStore}</CellRowTable>
              <CellRowTable>{key.storeName}</CellRowTable>
              <CellRowTable>{key.shopifyKey.accessToken}</CellRowTable>
              <CellRowTable>{key.shopifyKey.apiKey}</CellRowTable>
              <CellRowTable>{key.shopifyKey.secretApiKey}</CellRowTable>
              <CellRowTable>
                <div className="flex gap-2">
                  <ButtonTable
                    Icon={PencilIcon}
                    color={
                      "from-green-400 via-green-500 to-green-600 focus:ring-green-300 dark:focus:ring-green-800"
                    }
                    onClick={() =>
                      handleDoubleClickRow(
                        key.shopifyKey.accessToken,
                        key.shopifyKey.apiKey,
                        key.shopifyKey.secretApiKey,
                        key.storeName,
                        key.aliasStore,
                        key.id
                      )
                    }
                  />
                  <ButtonTable
                    Icon={TrashIcon}
                    color={
                      "from-red-400 via-red-500 to-red-600 focus:ring-red-300 dark:focus:ring-red-800"
                    }
                    onClick={() => {
                      setRowToDelete(key);
                      setShowModal(true);
                    }}
                  />
                </div>
              </CellRowTable>
              {auth.roles.includes(1) && (
                <CellRowTable>
                  <div className="flex gap-2">
                    <ButtonTable
                      Icon={LinkIcon}
                      color={
                        "from-orange-400 via-orange-500 to-orange-600 focus:ring-orange-300 dark:focus:ring-orange-800"
                      }
                      isLoading={clickedRow == key && loadingCreateWebhooks}
                      withTooltip={true}
                      tooltipTitle={"Crea Webhook"}
                      placement={"top"}
                      onClick={() => onCreateWebhooksClick(key)}
                    />
                    <ButtonTable
                      Icon={EyeIcon}
                      color={
                        "from-purple-400 via-purple-500 to-purple-600 focus:ring-purple-300 dark:focus:ring-purple-800"
                      }
                      isLoading={clickedRow == key && loadingWebhooks}
                      withTooltip={true}
                      tooltipTitle={"Mostra Webhooks"}
                      placement={"top"}
                      onClick={() => onGetWebhooksClick(key)}
                    />
                  </div>
                </CellRowTable>
              )}
            </RowTable>
          ))}
        </BodyTable>
      </ListTable>

      <ModalMessage
        rowToDelete={rowToDelete}
        showModal={showModal}
        setShowModal={setShowModal}
        setRowToDelete={setRowToDelete}
      />
    </>
  );
}
