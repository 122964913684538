import React from "react";

function TutorialPage() {
  return (
    <main className="mt-4 flex gap-2 flex-wrap px-4">
      <div className="bg-gray-100 py-2 px-3 rounded-md">
        <iframe
          className="rounded-md"
          width="360"
          height="auto"
          src="https://www.youtube.com/embed/RM1yubIzRBA"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>{" "}
        <h2 className="text-xl mt-2 uppercase underline">
          Tutorial integrazione chiave api
        </h2>
      </div>
      <div className="bg-gray-100 py-2 px-3 rounded-md">
        <iframe
          className="rounded-md"
          width="360"
          height="auto"
          src="https://www.youtube.com/embed/N-Zg8ORgaRY"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>{" "}
        <h2 className="text-xl mt-2 uppercase underline">
          Tutorial Evasione Ordini
        </h2>
      </div>
    </main>
  );
}

export default TutorialPage;
