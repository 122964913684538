import { userApi } from "./userApi";

const privacyPolicyApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    savePrivacyPolicy: builder.mutation({
      query: ({ privacyPolicy, ipAddress, isReaded }) => ({
        url: `privacy-policy/save`,
        method: "POST",
        body: { privacyPolicy, ipAddress, isReaded },
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useSavePrivacyPolicyMutation } = privacyPolicyApi;
