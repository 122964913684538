import React, { useState } from "react";
import { useSelector } from "react-redux";
import TextCopyToClipboard from "./TextCopyToClipboard";
import InputToggleEdit from "./InputToggleEdit";
import { useEditProductCatalogMutation } from "../../services/catalogApi";
import {
  CurrencyEuroIcon,
  DocumentTextIcon,
  EyeIcon,
  PencilIcon,
  QrcodeIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { Link } from "react-router-dom";
import ShowMoreLess from "../ShowMoreLess";
import moment from "moment";
import { InputField } from "../RegistrationForm";
import { useAddOneProductCatalogInCartMutation } from "../../services/productCatalogInCartApi";

function CatalogProductBox({
  product,
  setProdToDelete,
  setShowPopupConfirmation,
}) {
  const [
    editProductCatalog,
    {
      isLoading: editing,
      isError: eventErrorEditing,
      isSuccess: edited,
      error: textErrorEditing,
    },
  ] = useEditProductCatalogMutation();

  const [prod, setProd] = useState({ ...product, qty: 1 });
  var auth = useSelector((state) => state.auth);

  // Calcolo della data per determinare se il prodotto è stato aggiunto nelle ultime due settimane
  const isNewProduct = moment().diff(moment(product.dateCreated), "weeks") < 2;

  const [addOneProductCatalogInCart] = useAddOneProductCatalogInCartMutation();

  const addToCart = async (productCatalogInCart) => {
    await addOneProductCatalogInCart(productCatalogInCart).then((res) => {
      if (res.error) {
        return;
      }
    });
  };

  return (
    <div
      key={prod.id}
      className="relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white"
    >
      {/* Badge Fuori Stock */}
      {prod.stockQuantity === 0 && (
        <div className="absolute top-2 right-2 bg-red-600 text-white text-xs font-bold py-1 px-2 rounded-full z-30">
          FUORI STOCK
        </div>
      )}
      {/* Badge New Product */}
      {isNewProduct && (
        <div className="absolute top-2 left-2 bg-green-600 text-white text-md font-bold py-1 px-2 rounded-full z-30">
          NEW
        </div>
      )}

      <div className="flex justify-center bg-white aspect-h-4 aspect-w-4 sm:aspect-none sm:h-96 relative">
        <img
          src={prod?.productCatalogImage[0]?.src ?? ""}
          alt={prod.imageAlt}
          className="object-cover object-center h-full sm:h-full"
        />
      </div>
      <div className="flex flex-1 flex-col space-y-2 p-4">
        <h3 className="text-xl font-medium text-gray-900 flex gap-2 flex-wrap items-center">
          <a href={prod.href}>{prod.name}</a>
          {!product.visible && auth.roles.find((r) => r == 1) && (
            <div className=" bg-black text-white text-sm font-bold py-1 pr-2 pl-1 rounded-full z-30 flex gap-2">
              <EyeIcon className="h-5 w-5" />
              <span>NON VISIBILE</span>
            </div>
          )}
        </h3>
        <TextCopyToClipboard text={prod.sku} textHover={"COPIA SKU"} />
        <ShowMoreLess maxLength={150} text={prod.description} />{" "}
        <div className="flex flex-1 flex-col justify-end">
          <p className="text-sm italic text-gray-500">{prod.options}</p>
          <div className="flex gap-1 items-center w-full text-base font-medium text-gray-900">
            {auth.roles.includes(1) ? (
              <div className="flex flex-col gap-2 w-full">
                <div className="flex gap-2 items-center justify-between">
                  <p>Nome: </p>

                  <InputToggleEdit
                    Icon={DocumentTextIcon}
                    isLoading={editing}
                    defaultValue={prod.name}
                    hintTextDisable={"Nome Prodotto"}
                    hintTextEnable={""}
                    setValue={(value) => {
                      setProd({ ...prod, name: value });
                    }}
                    onEndEdit={(isEdited) =>
                      isEdited
                        ? editProductCatalog(prod)
                        : setProd({
                            ...prod,
                            name: product.name,
                          })
                    }
                  />
                </div>
                <div className="flex gap-2 items-center justify-between">
                  <p>A Listino: </p>

                  <InputToggleEdit
                    Icon={CurrencyEuroIcon}
                    isLoading={editing}
                    defaultValue={prod.adminPrice}
                    hintTextDisable={"Prezzo Fornitore"}
                    hintTextEnable={""}
                    setValue={(value) => {
                      setProd({ ...prod, adminPrice: value });
                    }}
                    onEndEdit={(isEdited) =>
                      isEdited
                        ? editProductCatalog(prod)
                        : setProd({
                            ...prod,
                            adminPrice: product.adminPrice,
                          })
                    }
                  />
                </div>
                <div className="flex gap-2 items-center justify-between">
                  <p>Al dettaglio: </p>
                  <InputToggleEdit
                    Icon={CurrencyEuroIcon}
                    isLoading={editing}
                    defaultValue={prod.price}
                    hintTextDisable={"Prezzo Cliente"}
                    hintTextEnable={""}
                    setValue={(value) => {
                      setProd({ ...prod, price: value });
                    }}
                    onEndEdit={(isEdited) =>
                      isEdited
                        ? editProductCatalog(prod)
                        : setProd({ ...prod, price: product.price })
                    }
                  />
                </div>
                <div className="flex gap-2 items-center justify-between ">
                  <p>SKU: </p>
                  <InputToggleEdit
                    Icon={QrcodeIcon}
                    isLoading={editing}
                    defaultValue={prod.sku}
                    hintTextDisable={"Inserisci sku"}
                    hintTextEnable={""}
                    setValue={(value) => {
                      setProd({ ...prod, sku: value });
                    }}
                    onEndEdit={(isEdited) =>
                      isEdited
                        ? editProductCatalog(prod)
                        : setProd({ ...prod, sku: product.sku })
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="flex-col w-full">
                <span className="text-xl">{`${prod.price}€`}</span>
                {/* <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                  AGGIUNGI AL CARRELLO
                </button> */}
                {prod.stockQuantity > 0 && (
                  <div className="flex justify-end  w-full items-center mt-4 flex-wrap gap-2">
                    <div className="flex gap-2 items-center ">
                      <input
                        type="number"
                        step={1}
                        className=" bg-gray-100 text-end h-10 text-xl rounded-md"
                        value={prod?.qty ?? 1}
                        onChange={(e) =>
                          setProd({ ...prod, qty: e.target.value })
                        }
                      />
                      <span>PZ</span>
                    </div>
                    <button
                      onClick={() => addToCart(prod)}
                      className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-full sm:w-54 "
                    >
                      AGGIUNGI AL CARRELLO
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="flex justify-between mt-6">
            <div className="flex gap-2">
              {auth.roles.includes(1) ? (
                <>
                  <Link
                    className="bg-red-900 rounded-md shadow flex justify-center items-center w-8 h-8 cursor-pointer"
                    onClick={() => {
                      setProdToDelete(prod);
                      setShowPopupConfirmation(true);
                    }}
                  >
                    <TrashIcon className="h-6 w-6 text-white" />
                  </Link>
                  <Link
                    to={"/product"}
                    state={prod}
                    className="bg-gray-900 rounded-md shadow flex justify-center items-center w-8 h-8 cursor-pointer"
                  >
                    <PencilIcon className="h-6 w-6 text-white" />
                  </Link>
                </>
              ) : (
                <></>
              )}
            </div>
            {/* <ShopifyAddProduct product={prod} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CatalogProductBox;
