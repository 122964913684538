import RowsPageShopifyOrder from "./RowsPageShopifyOrder";

function NavShopifyOrders({ numOrders, page, setPage, rowsPage, setRowsPage }) {
  return (
    <nav
      className="flex items-center justify-between  bg-white px-4 py-3 sm:px-6"
      aria-label="Pagination"
    >
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          <span className="font-medium">{page}</span> di{" "}
          <span className="font-medium">
            {roundUp((numOrders + 1) / rowsPage)}
          </span>{" "}
          | <span className="font-medium">Visualizza</span>{" "}
          <RowsPageShopifyOrder setRowsPage={setRowsPage} rowsPage={rowsPage} />{" "}
          <span className="font-medium"> di {numOrders} risultati</span>
        </p>
      </div>
      <div className="flex flex-1 justify-between sm:justify-end">
        <a
          onClick={() => setPage(page == 1 ? 1 : (page -= 1))}
          className="cursor-pointer relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
          Indietro
        </a>
        <a
          onClick={() => setPage((page += 1))}
          className="cursor-pointer relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0"
        >
          Avanti
        </a>
      </div>
    </nav>
  );
}
function roundUp(floatNum) {
  if (floatNum % 1 !== 0) {
    // Se la parte decimale non è zero
    return Math.ceil(floatNum); // Arrotonda per eccesso
  } else {
    return floatNum; // Altrimenti, il numero è già intero, quindi restituisci lo stesso numero
  }
}
export default NavShopifyOrders;
