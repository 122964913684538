import { userApi } from "./userApi";

const confirmOrderPriceListApi = userApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllConfirmOrderPriceList: builder.query({
      query: () => `confirm-order-price-list/get-all`,
      providesTags: ["ConfirmOrderPriceList"],
    }),
    addConfirmOrderPriceList: builder.mutation({
      query: (confirmOrderPriceList) => {
        return {
          url: "confirm-order-price-list/add-one",
          method: "POST",
          body: { confirmOrderPriceList },
        };
      },
      invalidatesTags: ["ConfirmOrderPriceList"],
    }),
    updateConfirmOrderPriceList: builder.mutation({
      query: (confirmOrderPriceList) => {
        return {
          url: "confirm-order-price-list/update-one",
          method: "PUT",
          body: { confirmOrderPriceList },
        };
      },
      invalidatesTags: ["ConfirmOrderPriceList"],
    }),
    deleteConfirmOrderPriceList: builder.mutation({
      query: (confirmOrderPriceListId) => {
        return {
          url: `confirm-order-price-list/delete-one/${confirmOrderPriceListId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["ConfirmOrderPriceList"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllConfirmOrderPriceListQuery,
  useAddConfirmOrderPriceListMutation,
  useDeleteConfirmOrderPriceListMutation,
  useUpdateConfirmOrderPriceListMutation,
} = confirmOrderPriceListApi;
