export const FulfillmentStatus = {
  InLavorazione: 1,
  OrdineEvaso: 2,
  ImpossibileDaEvadere: 3,
};

export const FulfillmentStatusList = [
  FulfillmentStatus.InLavorazione,
  FulfillmentStatus.ImpossibileDaEvadere,
  FulfillmentStatus.OrdineEvaso,
];

export const FulfillmentStatusRadioButtons = [
  { id: 0, name: "Tutti" },
  { id: FulfillmentStatus.InLavorazione, name: "In Lavorazione" },
  {
    id: FulfillmentStatus.ImpossibileDaEvadere,
    name: "Impossibile Da Evadere",
  },
  { id: FulfillmentStatus.OrdineEvaso, name: "Ordine Evaso" },
];
