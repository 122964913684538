import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
  roles: [],
  subscriptionPrice: null,
  canSeeCatalog: false,
  termsAndConditions: null,
  privacyPolicy: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth(state, payload) {
      state.accessToken = payload.payload.accessToken;
      state.roles = payload.payload.roles;
      state.subscriptionPrice = payload.payload.subscriptionPrice;
      state.canSeeCatalog = payload.payload.canSeeCatalog;
      state.termsAndConditions = payload.payload.termsAndConditions;
      state.privacyPolicy = payload.payload.privacyPolicy;
    },
    setSubscription(state, payload) {
      state.subscriptionPrice = payload.payload.subscriptionPrice;
    },
    logout(state, payload) {
      state.accessToken = "";
      state.roles = [];
      state.subscriptionPrice = null;
    },
  },
});
export const { setAuth, logout, setSubscription } = authSlice.actions;
export default authSlice.reducer;
