import { UploadFile } from "@mui/icons-material";
import FileUploader from "../components/cod-remittance/FileUploader";
import { ListCodRemittance } from "../components/cod-remittance/ListCodRemittance";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ErrorBanner } from "../components/custom/ErrorBanner";
import { useSendRemittanceFileMutation } from "../services/remittanceApi";
import { toBase64 } from "../functions/file";
import HeaderTable from "../components/custom/HeaderTable";
import RowTable from "../components/custom/RowTable";
import HeaderColTable from "../components/custom/HeaderColTable";
import HiddenHeaderColTable from "../components/custom/HiddenHeaderColTable";
import BodyTable from "../components/custom/BodyTable";
import CellRowTable from "../components/custom/CellRowTable";
import ButtonTable from "../components/custom/ButtonTable";
import {
  DocumentIcon,
  InformationCircleIcon,
  PrinterIcon,
} from "@heroicons/react/outline";
import {
  useGetAllTransactionsMutation,
  useLazyGetCustomersSummaryBalanceQuery,
  useGetSummaryBalancePdfMutation,
} from "../services/cashFlowApi";
import ListTable from "../components/custom/ListTable";
import moment from "moment";
import { LoadingIcon } from "../components/custom/LoadingIcon";
import PopupDialog from "../components/custom/PopupDialog";
import { TableLoading } from "../components/shipment/LoadingTable";
import BadgeComponent from "../components/custom/BadgeComponent";
import PopupWireTransfer from "../components/customer/PopupWireTransfer";
import { download_pdf } from "../functions/download_pdf";

import FileSaver from "file-saver";
import { exportToExcelBlob } from "../functions/export_to_excel";

export const CodRemittancePage = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [customerSelected, setCustomerSelected] = useState({}); //cliente singolo
  const [showPopupRiepilogo, setShowPopupRiepilogo] = useState(false);
  const [openPopupWireTransfer, setOpenPopupWireTransfer] = useState(false);
  const [customersSelected, setCustomersSelected] = useState([]); //lista di clienti
  const [isLoadingPrintingAll, setIsLoadingPrintingAll] = useState(false);

  const [filterStartShippingDate, setFilterStartShippingDate] = useState(
    moment().add(-7, "days").format("yyyy-MM-DD")
  );
  const [filterEndShippingDate, setFilterEndShippingDate] = useState(
    moment().format("yyyy-MM-DD")
  );

  const [filterRemittanceDate, setRemittanceDate] = useState(
    moment().format("yyyy-MM-DD")
  );

  const [sendRemittanceFile, { data, isLoading, isSuccess, isError, error }] =
    useSendRemittanceFileMutation();

  const handleUpload = async () => {
    await sendRemittanceFile({
      base64: await toBase64(selectedFile),
      fileName: selectedFile.name,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setSelectedFile(null);
    } else if (isError) {
      setErrorMessage(error.data.message);
      setShowErrorMessage(true);
      setSelectedFile(null);
    }
  }, [isLoading]);

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [startDate, setStartDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const auth = useSelector((state) => state.auth);

  const handleChangeStartDate = (e) => {
    const date = new Date();
    //if (e.target.value == "") return;
    date.setDate(parseInt(e.target.value.split("-")[2]));
    date.setMonth(parseInt(e.target.value.split("-")[1]) - 1);
    date.setFullYear(parseInt(e.target.value.split("-")[0]));
    setStartDate(date.toISOString().substring(0, 10));
  };

  const handleChangeEndDate = (e) => {
    const date = new Date();
    //if (e.target.value == "") return;
    date.setDate(parseInt(e.target.value.split("-")[2]));
    date.setMonth(parseInt(e.target.value.split("-")[1]) - 1);
    date.setFullYear(parseInt(e.target.value.split("-")[0]));
    setEndDate(date.toISOString().substring(0, 10));
  };

  const [
    getCustomersSummaryBalance,
    {
      data: customersSummaryBalance,
      isLoading: loadingSummaryBalance,
      isSuccess: successSummaryBalance,
      isError: errorSummaryBalanceEvent,
      error: errorSummaryBalanceText,
    },
  ] = useLazyGetCustomersSummaryBalanceQuery();

  const [
    getAllTransactions,
    {
      data: transactions,
      isLoading: loadingTransactions,
      isSuccess: successTransactions,
      isError: errorTransactionsEvent,
      error: errorTransactionsText,
    },
  ] = useGetAllTransactionsMutation();

  const [
    getSummaryBalancePdf,
    {
      data: base64,
      isLoading: loadingSummaryBalancePdf,
      isSuccess: successSummaryBalancePdf,
      isError: errorSummaryBalancePdfEvent,
      error: errorSummaryBalancePdfText,
    },
  ] = useGetSummaryBalancePdfMutation();

  useEffect(() => {
    getAllTransactions({
      userId: customerSelected.id,
      startDate: filterStartShippingDate,
      endDate: filterEndShippingDate,
    });
  }, [filterEndShippingDate, filterStartShippingDate, filterRemittanceDate]);

  const onInfoClick = (customer) => {
    setCustomerSelected(customer);
    getAllTransactions({
      userId: customer.id ?? 0,
      startDate: filterStartShippingDate,
      endDate: filterEndShippingDate,
    }).then((res) => {
      if (res.error) {
        setCustomerSelected({});
        return;
      }
      setShowPopupRiepilogo(true);
    });
  };

  const onClickWireTransfer = () => {
    setOpenPopupWireTransfer(true);
  };

  const onClicPrintWeekSummary = (customer) => {
    setCustomerSelected(customer);
    getSummaryBalancePdf({
      userId: customer.id,
      startDate: filterStartShippingDate,
      endDate: filterEndShippingDate,
    }).then((res) => {
      if (res.error) {
        return;
      }
      const pdfBase64 = res.data;
      download_pdf(pdfBase64.base64String, pdfBase64.fileName);
    });
  };

  const onClickExportExcel = async () => {
    console.log(customersSummaryBalance);
    const excelData = customersSummaryBalance
      .filter((csb) => csb.da_bonificare > 0)
      .map((csb) => ({
        beneficiary_name: csb.full_name,
        iban: csb.iban,
        amount: csb.da_bonificare,
        currency: "EUR",
        reference: "RIMESSA CONTRASSEGNI + SALDO FATTURA",
      }));
    await exportToExcelBlob(
      excelData,
      `bonifici_${moment().format("YYYY_MM_DD_HH_mm_ss")}`
    );

    //FileSaver.saveAs(data, fileName + ".xlsx");
  };

  const onClickPrintAll = async () => {
    setIsLoadingPrintingAll(true);
    for (var i = 0; i < customersSelected.length; i++) {
      const customer = customersSelected[i];
      await getSummaryBalancePdf({
        userId: customer.id,
        startDate: filterStartShippingDate,
        endDate: filterEndShippingDate,
      }).then((res) => {
        if (res.error) {
          return;
        }
        const pdfBase64 = res.data;
        download_pdf(pdfBase64.base64String, pdfBase64.fileName);
      });
    }
    setIsLoadingPrintingAll(false);
    setCustomersSelected([]);
  };

  useEffect(() => {
    getCustomersSummaryBalance();
  }, [openPopupWireTransfer]);

  return (
    <main className="px-4">
      <div className="mt-6 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:px-6">
          {auth.roles.find((role) => role == 1) ? (
            <FileUploader
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              handleUpload={handleUpload}
              isLoading={isLoading}
            />
          ) : (
            <></>
          )}
          <div className="mt-3 order-last flex flex-wrap w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:pl-6 sm:leading-7">
            <div className="flex gap-2 align-middle">
              <label htmlFor="inizio-flusso">Da:</label>
              <input
                className="border rounded-md px-2"
                type="date"
                id="inizio-flusso"
                value={startDate}
                max={endDate}
                onChange={(e) => handleChangeStartDate(e)}
              />
            </div>
            <div className="flex gap-2 align-middle">
              <label htmlFor="fine-flusso">A:</label>
              <input
                className="border rounded-md px-2"
                type="date"
                id="fine-flusso"
                value={endDate}
                min={startDate}
                onChange={(e) => handleChangeEndDate(e)}
              />
            </div>
          </div>
        </div>
        <div className="px-4 py-5 sm:p-6">
          <ListCodRemittance startDate={startDate} endDate={endDate} />
        </div>
      </div>

      {auth.roles.includes(1) && (
        <main>
          <PopupWireTransfer
            open={openPopupWireTransfer}
            setOpen={setOpenPopupWireTransfer}
            userId={customerSelected?.id}
          />
          <div className="flex gap-2 items-end justify-between">
            <div className="flex gap-2">
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Data Inizio Spedizione
                </label>
                <input
                  className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                  type="date"
                  value={filterStartShippingDate}
                  onChange={(e) => setFilterStartShippingDate(e.target.value)}
                />
              </div>
              <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                  Data Fine Spedizione
                </label>
                <input
                  className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                  type="date"
                  value={filterEndShippingDate}
                  onChange={(e) => setFilterEndShippingDate(e.target.value)}
                />
              </div>{" "}
            </div>
            <div className="flex gap-2">
              <button
                disabled={isLoadingPrintingAll}
                onClick={() => onClickPrintAll()}
                type="button"
                className="bg-gradient-to-r from-gray-400 via-gray-500 to-gray-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-gray-300 dark:focus:ring-gray-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              >
                Stampa Tutto
                {isLoadingPrintingAll ? (
                  <LoadingIcon size={"h-4 w-4"} color={"text-white"} />
                ) : (
                  <PrinterIcon className="h-4 w-4" />
                )}
              </button>

              <button
                onClick={() => onClickExportExcel()}
                type="button"
                className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
              >
                Esporta XLSX
                <DocumentIcon className="h-4 w-4" />
              </button>
            </div>
          </div>
          <PopupDialog
            padding={""}
            open={showPopupRiepilogo}
            setOpen={setShowPopupRiepilogo}
          >
            <main className="mx-8 mt-6">
              <div className="flex gap-2 items-center">
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Data Inizio Spedizione
                  </label>
                  <input
                    className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                    type="date"
                    value={filterStartShippingDate}
                    onChange={(e) => setFilterStartShippingDate(e.target.value)}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    Data Fine Spedizione
                  </label>
                  <input
                    className="shadow-sm ring-1 ring-inset ring-gray-300 rounded-md bg-white py-1.5 px-3"
                    type="date"
                    value={filterEndShippingDate}
                    onChange={(e) => setFilterEndShippingDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="flex justify-between my-2">
                <span className=" inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                  {`${transactions?.length} transazioni`}
                </span>
                <ButtonTable
                  onClick={() => onClicPrintWeekSummary(customerSelected)}
                  Icon={PrinterIcon}
                  color={
                    "from-gray-400 via-gray-500 to-gray-600 focus:ring-gray-300 dark:focus:ring-gray-800"
                  }
                />
              </div>
              {loadingTransactions ? (
                <TableLoading />
              ) : successTransactions ? (
                transactions?.map((transaction, index) => (
                  <>
                    <h2 key={index} className="font-bold ">
                      {transaction.tracking_number}
                    </h2>

                    <ListTable>
                      {transaction.items.map((item, index) => (
                        <BodyTable>
                          <RowTable>
                            <CellRowTable>{item.created_date}</CellRowTable>
                            <CellRowTable>
                              {item.product_name.substring(0, 20)}
                            </CellRowTable>
                            <CellRowTable>{item.qty}</CellRowTable>
                            <CellRowTable>{item.total_amount}</CellRowTable>
                          </RowTable>
                        </BodyTable>
                      ))}
                    </ListTable>
                  </>
                ))
              ) : (
                <></>
              )}
            </main>
            <div className="w-full bg-white sticky bottom-0 py-2 px-3 shadow-sm">
              <div className="flex justify-between  items-center">
                <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-md font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
                  {`Totale. ${transactions
                    ?.reduce((accumulator, transaction) => {
                      return accumulator + transaction.final_amount;
                    }, 0)
                    .toFixed(2)}€`}
                </span>

                <button
                  onClick={() => onClickWireTransfer()}
                  className="z-50 bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                >
                  Bonifica{" "}
                  {`${
                    (customerSelected?.da_bonificare ?? 0) < 0
                      ? 0
                      : customerSelected?.da_bonificare ?? 0
                  }€`}
                </button>
              </div>
            </div>
          </PopupDialog>

          <ListTable className="mt-2">
            <HeaderTable>
              <RowTable>
                <HeaderColTable>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      e.target.checked
                        ? setCustomersSelected(customersSummaryBalance ?? [])
                        : setCustomersSelected([])
                    }
                  />
                </HeaderColTable>
                <HeaderColTable>Cliente</HeaderColTable>
                <HeaderColTable>Da Bonificare</HeaderColTable>
                <HiddenHeaderColTable />
              </RowTable>
            </HeaderTable>
            <BodyTable>
              {loadingSummaryBalance ? (
                <LoadingIcon color={"text-black"} />
              ) : successSummaryBalance ? (
                customersSummaryBalance?.map((customerBalance, index) => (
                  <RowTable key={index}>
                    <CellRowTable>
                      <input
                        type="checkbox"
                        checked={
                          customersSelected.filter(
                            (cs) => cs.id == customerBalance.id
                          ).length > 0
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCustomersSelected([
                              ...customersSelected,
                              customerBalance,
                            ]);
                            return;
                          }
                          setCustomersSelected(
                            customersSelected.filter(
                              (cs) => cs.id !== customerBalance.id
                            )
                          );
                        }}
                      />
                    </CellRowTable>
                    <CellRowTable>{customerBalance.full_name}</CellRowTable>
                    <CellRowTable>{customerBalance.da_bonificare}</CellRowTable>
                    <CellRowTable>
                      <div className="flex gap-2">
                        <ButtonTable
                          Icon={InformationCircleIcon}
                          color={
                            "from-blue-400 via-blue-500 to-blue-600 focus:ring-blue-300 dark:focus:ring-blue-800"
                          }
                          onClick={() => onInfoClick(customerBalance)}
                        />
                        <ButtonTable
                          isLoading={
                            loadingSummaryBalancePdf &&
                            customerSelected.id == customerBalance.id
                          }
                          onClick={() =>
                            onClicPrintWeekSummary(customerBalance)
                          }
                          Icon={PrinterIcon}
                          color={
                            "from-gray-400 via-gray-500 to-gray-600 focus:ring-gray-300 dark:focus:ring-gray-800"
                          }
                        />
                      </div>
                    </CellRowTable>
                  </RowTable>
                ))
              ) : (
                <></>
              )}
            </BodyTable>
          </ListTable>
        </main>
      )}

      <ErrorBanner
        message={errorMessage}
        show={showErrorMessage}
        setShow={setShowErrorMessage}
      />
    </main>
  );
};
