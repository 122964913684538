import React, { useEffect } from "react";
import {
  useGetBalanceQuery,
  useLazyGetBalanceQuery,
} from "../services/cashFlowApi";
import { LoadingIcon } from "./custom/LoadingIcon";
import { useSelector } from "react-redux";
import { RefreshIcon } from "@heroicons/react/outline";
import { WalletOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

function BalanceAmount() {
  var auth = useSelector((state) => state.auth);
  const {
    data: cashFlow,
    isLoading,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetBalanceQuery(undefined, {
    skip: auth?.roles?.includes(1) ?? true,
  });
  // const [getBalance, { data: cashFlow, isLoading, isSuccess, isError, error }] =
  //   useLazyGetBalanceQuery();

  // useEffect(() => {
  //   if (!auth?.roles?.includes(1)) {
  //     getBalance();
  //   }
  // }, []);

  return isLoading ? (
    <LoadingIcon className="h-4 w-4" />
  ) : isSuccess ? (
    <>
      {!isLoading && (
        <RefreshIcon
          className="h-6 w-6 cursor-pointer"
          onClick={() => refetch()}
        />
      )}
      <Tooltip title="Saldo" placement="bottom">
        <span
          className={`inline-flex items-center gap-x-1.5 rounded-md  px-1.5 py-0.5 text-lg font-medium ${
            (cashFlow?.balance ?? 0) < 0
              ? "text-red-700 bg-red-100"
              : "text-green-700 bg-green-100"
          } `}
        >
          {/* <svg
          className={`h-1.5 w-1.5 ${
            (cashFlow?.balance ?? 0) < 0 ? "fill-red-500" : "fill-green-500"
          }`}
          viewBox="0 0 6 6"
          aria-hidden="true"
        >
          <circle cx={3} cy={3} r={3} />
        </svg> */}
          <WalletOutlined />
          {cashFlow?.balance ?? 0}€
        </span>
      </Tooltip>
    </>
  ) : isError ? (
    <span>{error?.data ?? ""}</span>
  ) : (
    <></>
  );
}

export default BalanceAmount;
