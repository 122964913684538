import React from "react";

function CellRowTable({ children, textAlign }) {
  return (
    <td
      className={`py-4 pl-4 pr-3 text-sm font-small text-gray-900 sm:pl-3 ${
        textAlign && textAlign
      }`}
    >
      {children}
    </td>
  );
}

export default CellRowTable;
