import React, { useState } from "react";
import CatalogProductBox from "./CatalogProductBox";
import ModalConfirmationRequest from "../custom/ModalConfirmationRequest";
import { useDeleteProductCatalogMutation } from "../../services/catalogApi";

function CatalogProductsList({ products }) {
  const [prodToDelete, setProdToDelete] = useState({});
  const [showPopupToConfirm, setShowPopupToConfirm] = useState(false);

  const [
    deleteProductCatalog,
    {
      isLoading: deleting,
      isError: eventErrorDeleting,
      isSuccess: deleted,
      error: textErrorDeleting,
    },
  ] = useDeleteProductCatalogMutation();

  const handleDeleteConfirmation = async () => {
    await deleteProductCatalog(prodToDelete.productCatalogId).unwrap();
    setShowPopupToConfirm(false);
  };

  return (
    <section
      aria-labelledby="product-heading"
      className="mt-6 lg:col-span-2 lg:mt-0 xl:col-span-3"
    >
      <h2 id="product-heading" className="sr-only">
        Products
      </h2>

      <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-4 lg:grid-cols-3">
        <ModalConfirmationRequest
          handleConfirm={handleDeleteConfirmation}
          showModal={showPopupToConfirm}
          setShowModal={setShowPopupToConfirm}
          message={`Confermi di voler cancellare '${prodToDelete.name}' ?`}
        />
        {products?.map((product) => (
          <CatalogProductBox
            product={product}
            key={product.productCatalogId}
            setProdToDelete={setProdToDelete}
            setShowPopupConfirmation={setShowPopupToConfirm}
          />
        ))}
      </div>
    </section>
  );
}

export default CatalogProductsList;
