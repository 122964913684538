import { PrinterIcon } from "@heroicons/react/solid";
import React from "react";
import { usePrintWaybillShipmentsMutation } from "../../services/shipmentApi";
import { download_pdf } from "../../functions/download_pdf";
import moment from "moment/moment";
import { LoadingIcon } from "./LoadingIcon";

function ButtonFileCustom({ isLoading, onClick, acceptedExt }) {
  return (
    <div className="relative inline-flex items-center">
      <input
        accept={acceptedExt}
        onChange={(e) => onClick(e)}
        type="file"
        className="bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center  inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
      />
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
          <LoadingIcon size="h-4 w-4" color={"text-gray-900"} />
        </div>
      )}
    </div>
  );
}

export default ButtonFileCustom;
