import { useEffect, useState } from "react";
import {
  useGetAllShipmentsQuery,
  useDeleteOneMutation,
  useGetAllShipmentsMutation,
  useGetShipmentPagesMutation,
} from "../../services/shipmentApi";
import { LoadingIcon } from "../custom/LoadingIcon";
import ModalTracking from "../order/ModalTracking";
import { PopupEditShipmentCost } from "./PopupEditShipmentCost";
import { useSelector } from "react-redux";
import moment from "moment";
import { TrackingButton } from "../order/TrackingButton";
import { PrinterIcon, TrashIcon } from "@heroicons/react/outline";
import { DownloadPdfShipment } from "../order/DownloadPdfOrder";
import PrintAllButton from "./PrintAllButton";
import NavShipmentList from "./NavShipmentList";
import ModalConfirmationRequest from "../custom/ModalConfirmationRequest";
import SimpleNotification from "../SimpleNotification";
import { TableLoading } from "./LoadingTable";
import TextCopyToClipboard from "../catalog/TextCopyToClipboard";
import { SpedisciOnlineStatusEnum } from "../../enums/SpedisciOnlineStatusEnum";
import ButtonGestioneGiacenza from "./ButtonGestioneGiacenza";
import ProductsOrderTable from "../order/NewShopifyOrderList/ProductsOrderTable";

export const ListShipment = ({
  showOrderProducts,
  shipmentOnHold,
  shipmentsToPrint,
  setShipmentsToPrint,
  shipmentsLength,
  shipments,
  page,
  rowsPage,
  setPage,
  setRowsPage,
  isLoading,
  isSuccess,
  isError,
  error,
}) => {
  const auth = useSelector((state) => state.auth);

  const [shipmentToDelete, setShipmentToDelete] = useState();
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [showConfirmationMessage, setShowConfirmationMessage] = useState(false);
  const [show, setShow] = useState(false);

  const [openTracking, setOpenTracking] = useState(false);
  const [openEditShippingCostPopup, setOpenEditShippingCostPopup] =
    useState(false);
  const [tracking, setTracking] = useState("");
  //const [shipmentsToPrint, setShipmentsToPrint] = useState([]);

  const [
    deleteOne,
    {
      isLoading: deleting,
      isSuccess: deleted,
      isError: deleteOnError,
      error: deleteError,
    },
  ] = useDeleteOneMutation();

  const onAddShipToPrint = async (e, shipment) => {
    if (e.target.checked) {
      setShipmentsToPrint([...shipmentsToPrint, shipment]);
    } else {
      setShipmentsToPrint([
        ...shipmentsToPrint.filter((sh) => sh.order_id !== shipment.order_id),
      ]);
    }
  };

  const onAddAllShipToPrint = async (e) => {
    if (e.target.checked) {
      setShipmentsToPrint(shipments?.filter((ship) => !ship.is_printed));
    } else {
      setShipmentsToPrint([]);
    }
  };

  const handleDeleteConfirmation = async (shipment) => {
    deleteOne({ shipment: shipmentToDelete });
    setShowConfirmationMessage(false);
  };

  useEffect(() => {
    if (deleted) {
      setShow(true);
    }
  }, [deleting]);
  //console.log(shipments);
  return (
    <>
      <PopupEditShipmentCost
        open={openEditShippingCostPopup}
        setOpen={setOpenEditShippingCostPopup}
      />
      <ModalTracking
        open={openTracking}
        setOpen={setOpenTracking}
        trackingNumber={tracking}
      />

      <NavShipmentList
        shipmentsLength={shipmentsLength}
        page={page}
        setPage={setPage}
        rowsPage={rowsPage}
        setRowsPage={setRowsPage}
      />
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <ModalConfirmationRequest
          handleConfirm={handleDeleteConfirmation}
          showModal={showConfirmationMessage}
          setShowModal={setShowConfirmationMessage}
          message={confirmationMessage}
        />
        <SimpleNotification
          show={show}
          setShow={setShow}
          message={"Spedizione Eliminata!"}
        />
        {isLoading ? (
          <TableLoading />
        ) : (
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
              <tr>
                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                  <input
                    type="checkbox"
                    onChange={(e) => onAddAllShipToPrint(e)}
                  />
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3"
                >
                  Data
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3"
                >
                  Nr. Ordine
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3"
                >
                  LDV
                </th>
                {/* <th
                scope="col"
                className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900 sm:pl-3"
              >
                Cliente CODFacile
              </th> */}
                <th
                  scope="col"
                  className="px-3 py-3.5 text-start text-sm font-semibold text-gray-900"
                >
                  Destinatario
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center"
                >
                  COD
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5  text-sm font-semibold text-gray-900 "
                >
                  Svincolo
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5  text-sm font-semibold text-gray-900 "
                >
                  Stato
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center"
                >
                  Costo Spedizione
                </th>
                {(shipmentOnHold ?? false) == true && (
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-sm font-semibold text-gray-900 text-center"
                  >
                    Costo Svincolo
                  </th>
                )}
                {(shipmentOnHold ?? false) == false && (
                  <>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                    >
                      <span className="sr-only"></span>
                    </th>

                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                    >
                      <span className="sr-only"></span>
                    </th>
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {isSuccess ? (
                shipments?.map((shipment, index) => (
                  <tr
                    key={index}
                    className="text-gray-900 shopify-order-row odd:bg-white even:bg-gray-50"
                  >
                    <td className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                      <input
                        type="checkbox"
                        checked={shipmentsToPrint.find(
                          (shipPrint) => shipment.order_id == shipPrint.order_id
                        )}
                        disabled={shipment.is_printed}
                        onChange={(e) => onAddShipToPrint(e, shipment)}
                      />
                    </td>
                    <td className="relative py-5 pr-6">
                      {moment(shipment.created_date).format("DD/MM/yyyy")}
                    </td>
                    <td className="flex flex-col relative py-5 pr-6">
                      {shipment.order_number}
                      {/* <br /> */}
                      <span>{shipment.alias_store}</span>
                      {/* <br /> */}
                      <div>
                        <ProductsOrderTable
                          orderId={shipment.order_id}
                          showOrderProducts={showOrderProducts}
                        />
                      </div>
                    </td>
                    <td className="relative py-5 pr-6">
                      <span>
                        <TextCopyToClipboard
                          text={shipment.tracking_number}
                          textHover={"COPIA TRACKING"}
                        />
                      </span>
                      {shipment.product_from_inventory > 0 ? (
                        <span className="rounded-full bg-green-100 px-2 py-1.5 text-xs font-medium text-green-700 ">
                          Prodotti da inventario
                        </span>
                      ) : (
                        ""
                      )}
                    </td>
                    {/* <td className="py-5 pr-6 sm:table-cell">
                    {shipment.codfacile_customer}
                  </td> */}
                    <td className="py-5 pr-6 sm:table-cell">
                      {shipment.customer}
                      <br />
                      {shipment.shipping_address}
                    </td>
                    <td className="px-3 py-3.5 sm:table-cell text-center">
                      {shipment.financial_status == "pending"
                        ? shipment.order_price
                        : ""}
                    </td>

                    <td className="px-3 py-4 text-sm text-gray-500">
                      {shipment.spedisci_online_status ==
                      SpedisciOnlineStatusEnum["IN GIACENZA"] ? (
                        <ButtonGestioneGiacenza shipmentId={shipment.id} />
                      ) : (
                        <></>
                      )}
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500 flex-col">
                      {(shipment?.status ?? "") != "" && (
                        <span
                          className={`inline-flex items-center gap-x-1.5 rounded-full  px-2 py-1 text-xs font-medium ${
                            shipment.status == "Spedizione non trovata"
                              ? "text-red-700 bg-red-100"
                              : shipment.status
                                  .toUpperCase()
                                  .includes("CONSEGNATA") //== "CONSEGNATA"
                              ? "text-green-700 bg-green-100"
                              : "text-yellow-800 bg-yellow-100"
                          }`}
                        >
                          <svg
                            className={`h-1.5 w-1.5 ${
                              shipment.status == "Spedizione non trovata"
                                ? "fill-red-500"
                                : shipment.status
                                    .toUpperCase()
                                    .includes("CONSEGNATA")
                                ? "fill-green-500"
                                : "fill-yellow-500"
                            } `}
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          {shipment.status}
                        </span>
                      )}
                      {shipment.tracking_number == undefined ? (
                        <></>
                      ) : (
                        <div className="flex flex-wrap gap-2">
                          <TrackingButton
                            trackingNumber={shipment.tracking_number}
                          />
                        </div>
                      )}
                    </td>
                    <td className="py-5 pr-6 sm:table-cell text-center font-medium text-lg ">
                      <span
                        className={`${
                          shipment.remittance_loaded
                            ? "text-white bg-green-600 rounded-md py-1 px-2 font-bold"
                            : "text-gray-500"
                        }`}
                      >
                        {auth.roles.includes(1)
                          ? shipment.shipping_cost
                          : shipment.shipping_price_customer}
                      </span>
                    </td>
                    {(shipmentOnHold ?? false) == true && (
                      <td className="relative py-3.5 pl-3 pr-4 sm:pr-3 text-center font-medium text-lg text-gray-500">
                        {shipment.handling_cost ?? 0}
                      </td>
                    )}
                    {(shipmentOnHold ?? false) == false && (
                      <>
                        <td className="relative py-3.5 pl-3 pr-4 sm:pr-3 text-center ">
                          {auth.roles.includes(1) && (
                            <DownloadPdfShipment
                              disabled={!shipment.is_printed}
                              Icon={PrinterIcon}
                              order={{
                                id: shipment.order_id,
                                tracking_number: shipment.tracking_number,
                              }}
                              style={`${
                                shipment.is_to_print
                                  ? "text-black-600"
                                  : "text-black-200"
                              } h-4 w-4 cursor-pointer  hover:text-black-400`}
                            />
                          )}
                        </td>
                        <td className="relative py-3.5 pl-3 pr-4 sm:pr-3 text-center ">
                          {auth.roles.includes(1) && (
                            <button
                              onClick={() => {
                                setConfirmationMessage(
                                  `Sei sicuro di voler cancellare la spedizione con tracking '${shipment.tracking_number}'?`
                                );
                                setShipmentToDelete(shipment);
                                setShowConfirmationMessage(true);
                              }}
                              className="bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm px-2.5 py-2.5 text-center me-2 mb-2 inline-flex items-center gap-x-1.5 text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 "
                            >
                              <TrashIcon className={`h-4 w-4 cursor-pointer`} />
                            </button>
                          )}
                        </td>
                      </>
                    )}
                  </tr>
                ))
              ) : isError ? (
                <span>{error}</span>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        )}
      </div>
    </>
  );
};
