import { useEffect } from "react";
import { imagesUrl } from "../config/baseUrl";
import {
  useDeleteOneProductCatalogInCartMutation,
  useEditOneProductCatalogInCartMutation,
  useGetAllProductCatalogInCartQuery,
} from "../services/productCatalogInCartApi";
import { useGetShipmentCostProductsMutation } from "../services/shipmentApi";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import { InputField } from "../components/RegistrationForm";

const products = [
  {
    id: 1,
    name: "High Wall Tote",
    href: "#",
    price: "$210.00",
    color: "White and black",
    size: "15L",
    imageSrc:
      "https://tailwindui.com/img/ecommerce-images/checkout-page-07-product-01.jpg",
    imageAlt:
      "Front of zip tote bag with white canvas, white handles, and black drawstring top.",
  },
  // More products...
];

export default function CheckoutPage() {
  const {
    data: products,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllProductCatalogInCartQuery();
  const [getShipmentCostProducts, { data: shippingPriceListWeightRange }] =
    useGetShipmentCostProductsMutation();

  const [deleteOneProductCatalogInCart] =
    useDeleteOneProductCatalogInCartMutation();

  const [editOneProductCatalogInCart] =
    useEditOneProductCatalogInCartMutation();

  const amount = products?.reduce((accumulator, product) => {
    return accumulator + (product?.listPrice ?? 0) * (product?.quantity ?? 0);
  }, 0);

  useEffect(() => {
    getShipmentCostProducts(products);
  }, [products]);

  const onDeleteProductInCartClick = async (productCatalogInCart) => {
    deleteOneProductCatalogInCart(productCatalogInCart).then((res) => {
      if (res.error) {
        return;
      }
    });
  };

  const onChangeProductInCartQuantity = async (productInCart, qty) => {
    editOneProductCatalogInCart({
      ...productInCart,
      quantity: productInCart.quantity + qty,
    }).then((res) => {
      if (res.error) {
        return;
      }
    });
  };
  return (
    <div className="bg-white">
      {/* Background color split screen for large screens */}
      <div
        aria-hidden="true"
        className="fixed left-0 top-0 hidden h-full w-1/2 bg-white lg:block"
      />
      <div
        aria-hidden="true"
        className="fixed right-0 top-0 hidden h-full w-1/2 bg-green-900 lg:block"
      />

      <div className="relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 lg:pt-16">
        <h1 className="sr-only">Checkout</h1>

        <section
          aria-labelledby="summary-heading"
          className="bg-green-900 py-12 text-green-300 md:px-10 lg:col-start-2 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:bg-transparent lg:px-0 lg:pb-24 lg:pt-0"
        >
          <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
            <h2 id="summary-heading" className="sr-only">
              Order summary
            </h2>

            <dl>
              <dt className="text-sm font-medium">Amount due</dt>
              <dd className="mt-1 text-3xl font-bold tracking-tight text-white">
                $232.00
              </dd>
            </dl>

            <ul
              role="list"
              className="divide-y divide-white divide-opacity-10 text-sm font-medium"
            >
              {products?.map((product) => (
                <li
                  key={product.id}
                  className="flex items-start space-x-4 py-6"
                >
                  {(product?.productCatalog?.productCatalogImage[0]?.src ??
                    "") !== "" && (
                    <img
                      alt={product?.productCatalog?.name ?? ""}
                      src={`${imagesUrl}${
                        product?.productCatalog?.productCatalogImage[0]?.src ??
                        ""
                      }`}
                      className="h-20 w-20 flex-none rounded-md object-cover object-center"
                    />
                  )}
                  <div className="flex-auto space-y-1">
                    <h3 className="text-white">
                      {product.productCatalog.name}
                    </h3>{" "}
                    <div className="flex flex-1 items-center gap-4 text-sm">
                      <div className="flex gap-2 items-center">
                        <PlusCircleIcon
                          className="h-6 w-6 text-white cursor-pointer"
                          onClick={() =>
                            onChangeProductInCartQuantity(product, 1)
                          }
                        />
                        <span className="text-white text-xl">
                          {product.quantity}
                        </span>
                        <MinusCircleIcon
                          className="h-6 w-6 text-white cursor-pointer"
                          onClick={() =>
                            onChangeProductInCartQuantity(product, -1)
                          }
                        />
                      </div>

                      <div className="flex">
                        <button
                          onClick={() => onDeleteProductInCartClick(product)}
                          type="button"
                          className="font-medium text-green-900 bg-white px-2 rounded-md"
                        >
                          Rimuovi
                        </button>
                      </div>
                    </div>
                  </div>

                  <p className="flex-none text-base font-medium text-white">
                    {product.productCatalog.price}€
                  </p>
                </li>
              ))}
            </ul>

            <dl className="space-y-6 border-t border-white border-opacity-10 pt-6 text-sm font-medium">
              <div className="flex items-center justify-between">
                <dt>Prodotti</dt>
                <dd>{amount}€</dd>
              </div>

              <div className="flex items-center justify-between">
                <dt>Shipping</dt>
                <dd>$25.00</dd>
              </div>

              <div className="flex items-center justify-between">
                <dt>Taxes</dt>
                <dd>$47.60</dd>
              </div>

              <div className="flex items-center justify-between border-t border-white border-opacity-10 pt-6 text-white">
                <dt className="text-base">Total</dt>
                <dd className="text-base">$642.60</dd>
              </div>
            </dl>
          </div>
        </section>

        <section
          aria-labelledby="payment-and-shipping-heading"
          className="py-16 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-lg lg:pb-24 lg:pt-0"
        >
          <h2 id="payment-and-shipping-heading" className="sr-only">
            Payment and shipping details
          </h2>

          <form>
            <div className="mx-auto max-w-2xl px-4 lg:max-w-none lg:px-0">
              <div className="mt-10">
                <h3 className="text-lg font-medium text-gray-900">
                  Indirizzo di spedizione
                </h3>
                <InputField label={"Indirizzo"} />
                <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-3">
                  <InputField label={"Città"} />
                  <InputField label={"Provincia"} />
                  <InputField label={"Codice Postale"} />
                </div>
              </div>

              <div className="mt-10">
                <h3 className="text-lg font-medium text-gray-900">
                  Indirizzo di fatturazione
                </h3>

                <div className="mt-6 flex items-center">
                  <input
                    defaultChecked
                    id="same-as-shipping"
                    name="same-as-shipping"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                  />
                  <div className="ml-2">
                    <label
                      htmlFor="same-as-shipping"
                      className="text-sm font-medium text-gray-900"
                    >
                      Same as shipping information
                    </label>
                  </div>
                </div>
              </div>

              <div className="mt-10 flex justify-end border-t border-gray-200 pt-6">
                <button
                  type="submit"
                  className="rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                >
                  Paga Ora
                </button>
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}
