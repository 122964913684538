import {
  CheckCircleIcon,
  CheckIcon,
  CreditCardIcon,
  DocumentRemoveIcon,
  DownloadIcon,
  PrinterIcon,
  ShoppingCartIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import React, { useState } from "react";
import { DownloadPdfOrder } from "../DownloadPdfOrder";
import { LastTrackingStatusBadge } from "../LastTrackingStatusBadge";
import { TrackingButton } from "../TrackingButton";
import { useGetProductsOrderTableQuery } from "../../../services/orderApi";
import { LoadingIcon } from "../../custom/LoadingIcon";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { FulfillmentStatus } from "../../../enums/FulfillmentStatus";
import BadgeComponent from "../../custom/BadgeComponent";
import TextCopyToClipboard from "../../catalog/TextCopyToClipboard";
import ProductsOrderTable from "./ProductsOrderTable";
//var moment = require("moment-timezone");

function RowShopifyOrder({
  onEditFulfillmentRequest,
  showOrderProducts,
  order,
  selectedOrder,
  ordersToPay,
  printingMode,
  handleAddItem,
  handleItemToPrint,
  selectedOrderToPrint,
  auth,
  navigate,
  onClickPay,
  onAddToPay,
}) {
  return (
    <>
      <tr
        //onClick={() => navigate(`/orders/${order.id}`)}
        key={order.id}
        className={`shopify-order-row even:bg-gray-50 ${
          order.financial_status == "pending" && order.price == 0
            ? "bg-red-100"
            : ""
        }`}
      >
        {(order.error_generating_shipment.includes("Client error:") == "" &&
          auth?.roles?.includes(2)) ||
        auth.roles.includes(1) ? (
          <Tooltip
            title={order.error_generating_shipment
              .replace(
                'Client error: `POST https://rdf.spedisci.online/api/v2/shipping/create` resulted in a `400 Bad Request` response:\n{"error":"',
                ""
              )
              .replace("pi\\u00f9", "più")
              .replace('"}', "")
              .replace(" (truncated...)", "")
              .replaceAll("\\", "")}
          >
            <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
              {order.error_generating_shipment !== "" ? (
                <span className="relative flex h-3 w-3">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                  <span className="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                </span>
              ) : (
                ""
              )}
            </td>
          </Tooltip>
        ) : (
          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3"></td>
        )}

        <td
          onClick={() => handleAddItem(!selectedOrder.includes(order), order)}
          className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3 cursor-pointer hover:bg-gray-100"
        >
          {(order?.tracking_number ?? "") == "" &&
          (order?.fullfilment_status ?? "") == "" ? (
            <input
              checked={selectedOrder.includes(order)}
              onChange={(e) => handleAddItem(e.target.checked, order)}
              id={`print-${order.id}`}
              aria-describedby="comments-description"
              name={`print-${order.id}`}
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-green-facile focus:ring-green-facile"
              disabled={
                (order?.tracking_number ?? "") !== ""
                  ? true
                  : (order?.fullfilment_status ?? "") !== ""
                  ? true
                  : false
              }
            />
          ) : printingMode ? (
            <div className="flex flex-col items-center gap-1">
              <PrinterIcon className="h-4 w-4" />
              <input
                checked={selectedOrderToPrint.includes(order)}
                onChange={(e) => handleItemToPrint(e, order)}
                id={`print-${order.id}`}
                aria-describedby="comments-description"
                name={`print-${order.id}`}
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-green-facile focus:ring-green-facile"
              />
            </div>
          ) : (
            <></>
          )}
        </td>
        <td className="relative py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-3 flex items-center gap-1">
          {auth.roles.find((role) => role == 1) ? (
            <DownloadPdfOrder
              order={order}
              Icon={DownloadIcon}
              style={`h-4 w-4 text-gray-400 `}
            />
          ) : (
            <></>
          )}
        </td>
        <td className="cursor-pointer py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
          {` ${moment(order.created_date).format("DD/MM")}`}
          <br />
          {`${moment(order.created_date).format("HH:mm")}`}
        </td>
        <td className=" py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
          <div className="flex flex-col">
            <a
              className="cursor-pointer"
              target="_blank"
              href={`/orders/${order.id}`}
            >
              <div className="flex gap-3 items-center mb-0.5 w-max">
                <span>{order.order_number}</span>
              </div>
              <div>
                {order?.tags == "" ? (
                  ""
                ) : (
                  <>
                    {order.tags.split(",").map((tag) => (
                      <span
                        key={tag}
                        className="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10 mr-2"
                      >
                        {tag}
                      </span>
                    ))}
                    <br />
                  </>
                )}
                <span className="text-xs font-medium">
                  {(order?.alias_store ?? "") == ""
                    ? order.store_name
                    : order.alias_store}
                </span>
              </div>
            </a>
            <div>
              <ProductsOrderTable
                orderId={order.id}
                showOrderProducts={showOrderProducts}
              />
            </div>
          </div>
        </td>
        <td className="px-3 py-4 text-sm text-gray-500">
          <span className="flex gap-2 items-center">
            {(order?.fulfillment_status_name ?? "") != "" &&
              (order.fulfillment_status_id ==
              FulfillmentStatus.InLavorazione ? (
                <BadgeComponent
                  background={"yellow"}
                  color={"gray"}
                  text={order?.fulfillment_status_name ?? ""}
                />
              ) : order.fulfillment_status_id ==
                FulfillmentStatus.ImpossibileDaEvadere ? (
                <BadgeComponent
                  background={"red"}
                  color={"gray"}
                  text={order?.fulfillment_status_name ?? ""}
                />
              ) : order.fulfillment_status_id ==
                FulfillmentStatus.OrdineEvaso ? (
                <BadgeComponent
                  background={"green"}
                  color={"gray"}
                  text={order?.fulfillment_status_name ?? ""}
                />
              ) : (
                <></>
              ))}
            {(order?.fulfillment_status_name ?? "") != "" && (
              <Tooltip title={"Elimina Richiesta Evasione"} placement="top">
                <XCircleIcon
                  className="h-6 w-6 text-red-700 cursor-pointer"
                  onClick={() => onEditFulfillmentRequest(order)}
                />
              </Tooltip>
            )}
          </span>
          {order.fullfilment_status != "" && (
            <span
              className={`inline-flex items-center gap-x-0.5 rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ${
                order.fullfilment_status == "Completato"
                  ? "bg-green-50 text-green-700 ring-green-600/10"
                  : "bg-orange-50 text-orange-700 ring-orange-600/10"
              } `}
            >
              {order.fullfilment_status}
            </span>
          )}
        </td>
        <td className="px-3 py-4 text-sm text-gray-500">
          {order.customer}
          <br />
          <span className="font-bold">{order.shipping_address}</span>
        </td>
        {auth.roles.find((role) => role == 1) ? (
          <td className="px-3 py-4 text-sm text-gray-500">
            {order.codfacile_customer}
          </td>
        ) : (
          <></>
        )}

        <td className="px-3 py-4 text-sm text-gray-500">
          {(order?.status ?? "") != "" && (
            <span
              className={`inline-flex items-center gap-x-1.5 rounded-full  px-2 py-1 text-xs font-medium ${
                order.status == "Spedizione non trovata"
                  ? "text-red-700 bg-red-100"
                  : order.status == "CONSEGNATA"
                  ? "text-green-700 bg-green-100"
                  : "text-yellow-800 bg-yellow-100"
              }`}
            >
              <svg
                className={`h-1.5 w-1.5 ${
                  order.status == "Spedizione non trovata"
                    ? "fill-red-500"
                    : order.status == "CONSEGNATA"
                    ? "fill-green-500"
                    : "fill-yellow-500"
                } `}
                viewBox="0 0 6 6"
                aria-hidden="true"
              >
                <circle cx={3} cy={3} r={3} />
              </svg>
              {order.status}
            </span>
          )}

          <br />
          {order.tracking_number == undefined ? (
            <></>
          ) : (
            <TrackingButton trackingNumber={order.tracking_number} />
          )}
        </td>
        {/* <td className="px-3 py-4 text-sm text-gray-500">
          {order.products_price}€
        </td>
        <td className="px-3 py-4 text-sm text-gray-500">
          {order.shipping_price}
        </td> */}
        <td className={`px-3 py-4 text-sm `}>
          {order.financial_status === "pending" && (
            <span
              className={`${
                order.remittance_loaded
                  ? "text-white bg-green-600 rounded-md py-1 px-2 font-bold"
                  : "text-gray-500"
              }`}
            >
              {order.order_price}
            </span>
          )}
        </td>
        <td className="px-3 py-4 text-sm text-gray-500">
          {order.products_cost}
        </td>
        <td className="px-3 py-4 text-sm text-gray-500">
          {order.shipping_cost}
        </td>
        {/* <td className="px-3 py-4 text-sm text-gray-500">
          {auth.roles.includes(1)
            ? (
                order.products_price_customer -
                order.products_cost +
                (order.shipping_price_customer - order.shipping_cost)
              ).toFixed(2)
            : (
                order.order_price -
                order.products_cost -
                order.shipping_cost
              ).toFixed(2)}
        </td> */}
        {/* <td className="px-3 py-4 text-sm text-gray-500">
          {order.tracking_number == undefined ? (
            <></>
          ) : (
            <TrackingButton trackingNumber={order.tracking_number} />
          )}
        </td> */}
        <td className="px-3 py-4 text-sm text-gray-500 text-center flex gap-2">
          {order.financial_status != "pending" ? (
            <>
              <button
                disabled={(order?.amount_paid ?? 0) !== 0}
                onClick={() => onClickPay(order)}
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100"
              >
                {(order?.amount_paid ?? 0) == 0 ? (
                  <>
                    <CreditCardIcon
                      className="-ml-0.5 h-5 w-5"
                      aria-hidden="true"
                    />
                    PAGA
                  </>
                ) : (
                  <>
                    <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    PAGATO {order.amount_paid}€
                  </>
                )}
              </button>
              {/* TODO da reinserire per pagamento massivo degli ordini */}
              {/* {(order?.amount_paid ?? 0) == 0 ? (
                <button
                  disabled={(order?.amount_paid ?? 0) !== 0}
                  onClick={() => onAddToPay(order)}
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded bg-green-50 px-2 py-1 text-xs font-semibold text-green-600 shadow-sm hover:bg-green-100"
                >
                  {ordersToPay.find((op) => op.id == order.id) ? (
                    <CheckCircleIcon className="-ml-0.5 h-5 w-5" />
                  ) : (
                    <ShoppingCartIcon className="-ml-0.5 h-5 w-5" />
                  )}
                </button>
              ) : (
                <></>
              )} */}
            </>
          ) : (
            <button
              type="button"
              className=" rounded bg-orange-50 px-2 py-1 text-xs font-semibold text-orange-600 shadow-sm hover:bg-orange-100 cursor-default"
            >
              COD
            </button>
          )}
        </td>
      </tr>
      <tr>
        {(order.order_note?.trim() ?? "") == "" ? (
          <></>
        ) : (
          <>
            <td
              className="px-3 py-2 text-sm font-medium text-gray-900 uppercase text-right"
              colSpan="3"
            >
              nota:
            </td>
            <td className="pr-3 py-2 text-sm text-gray-500" colSpan="15">
              {order.order_note}
            </td>
          </>
        )}
      </tr>
    </>
  );
}

export default RowShopifyOrder;
