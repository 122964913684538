import { Radio, RadioGroup } from "@headlessui/react";
import React from "react";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function RadioGroupCard({ selectedValue, onChange, items }) {
  return (
    <RadioGroup
      value={selectedValue}
      onChange={(e) => {
        //console.log(e);
        onChange(e);
      }}
      className="-space-y-px rounded-md bg-white"
    >
      {items?.map((item, index) => (
        <Radio
          key={index}
          value={item}
          aria-label={item}
          // aria-description={setting.description}
          className={({ checked }) =>
            classNames(
              checked ? "z-10 border-green-200 bg-green-50" : "border-gray-200",
              `${
                index == 0
                  ? "rounded-tl-md rounded-tr-md"
                  : items.length - 1 == index
                  ? "rounded-bl-md rounded-br-md"
                  : ""
              } relative flex cursor-pointer border p-4 focus:outline-none`
            )
          }
        >
          {({ focus, checked }) => (
            <>
              <span
                className={classNames(
                  selectedValue.id == item.id
                    ? "border-transparent bg-green-600"
                    : "border-gray-300 bg-white",
                  focus ? "ring-2 ring-green-600 ring-offset-2" : "",
                  "mt-0.5 flex h-4 w-4 shrink-0 cursor-pointer items-center justify-center rounded-full border"
                )}
                aria-hidden="true"
              >
                <span className="h-1.5 w-1.5 rounded-full bg-white" />
              </span>
              <span className="ml-3 flex flex-col">
                <span
                  className={classNames(
                    checked ? "text-green-900" : "text-gray-900",
                    "block text-sm font-medium"
                  )}
                >
                  {item?.name ?? ""}
                </span>
                {/* <span
                className={classNames(
                  checked ? "text-green-700" : "text-gray-500",
                  "block text-sm"
                )}
              >
                {setting.description}
              </span> */}
              </span>
            </>
          )}
        </Radio>
      ))}
    </RadioGroup>
  );
}

export default RadioGroupCard;
