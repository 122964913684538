import { useState } from "react";
import ModalTracking from "./ModalTracking";
import { useLazyGetTrackingDetailsQuery } from "../../services/shipmentApi";

export const TrackingButton = ({ trackingNumber, text }) => {
  const [open, setOpen] = useState(false);
  const [
    getTrackingDetails,
    {
      data: trackingDetails,
      isLoading,
      isSuccess,
      isError,
      error,
      isUninitialized,
    },
  ] = useLazyGetTrackingDetailsQuery();

  const onClick = () => {
    setOpen(!open);
    getTrackingDetails({ trackingNumber });
  };

  return (
    <>
      <button
        onClick={() => onClick()}
        type="button"
        className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      >
        {text ?? "Tracciamento"}
      </button>

      <ModalTracking
        trackingNumber={trackingNumber}
        trackingDetails={trackingDetails}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export const TrackingText = ({ trackingNumber, text }) => {
  const [open, setOpen] = useState(false);
  const [
    getTrackingDetails,
    {
      data: trackingDetails,
      isLoading,
      isSuccess,
      isError,
      error,
      isUninitialized,
    },
  ] = useLazyGetTrackingDetailsQuery();

  const onClick = () => {
    setOpen(!open);
    getTrackingDetails({ trackingNumber });
  };

  return (
    <>
      <a
        onClick={() => onClick()}
        className="text-sm text-gray-500 cursor-pointer underline"
      >
        {text ?? "Tracciamento"}
      </a>

      <ModalTracking
        trackingNumber={trackingNumber}
        trackingDetails={trackingDetails}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};
