import { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSignUpMutation } from "../services/authApi";
import { useDispatch } from "react-redux";
import { setAuth } from "../features/auth/authSlice";
import { RegistrationForm } from "../components/RegistrationForm";
import { ErrorBanner } from "../components/custom/ErrorBanner";
import { useCheckAliasCodeQuery } from "../services/promotionApi";
import { LoadingIcon } from "../components/custom/LoadingIcon";
import axios from "axios";
//const { AddressValidationClient } = require("@googlemaps/addressvalidation").v1;

// Instantiates a client
export const RegistrationPage = () => {
  //const addressvalidationClient = new AddressValidationClient();
  const dispatch = useDispatch();
  const [signUp, { data, isLoading, isSuccess, isError, error }] =
    useSignUpMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/integration";
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [surname, setSurname] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [businessName, setBusinessName] = useState();
  const [vatNumber, setVatNumber] = useState();
  const [fiscalCode, setFiscalCode] = useState();
  const [address, setAddress] = useState();
  const [phone, setPhone] = useState();
  const [city, setCity] = useState();
  const [zipCode, setZipCode] = useState();
  const [province, setProvince] = useState();
  const [nation, setNation] = useState("");
  const [iban, setIban] = useState("");
  const [privacy, setPrivacy] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { promotionCode } = useParams();

  useEffect(() => {
    if (isSuccess) {
      dispatch(setAuth(data));

      setEmail("");
      setPassword("");
      navigate(from, { replace: true });
    } else if (isError) {
      const { message } = error.data;
      setErrorMessage(message);
      setShowErrorMessage(true);
    }
  }, [isLoading]);

  const handleSignUp = async (e) => {
    e.preventDefault(e);
    if (password != confirmPassword) {
      setErrorMessage("Le password non coincidono");
      setShowErrorMessage(true);
      return;
    }
    if ((name?.trim() ?? "") == "") {
      setErrorMessage("Inserisci un nome valido");
      setShowErrorMessage(true);
      return;
    }
    const response = await axios.get("https://api.ipify.org?format=json");
    await signUp({
      email,
      name,
      surname,
      password,
      confirmPassword,
      businessName,
      vatNumber,
      fiscalCode,
      address,
      phone,
      city,
      zipCode,
      province,
      nation,
      privacy,
      termsAndConditions,
      iban,
      promotionCode,
      ipAddress: response.data.ip,
    });
  };
  const handleEmail = (email) => {
    setEmail(email.target.value);
  };
  const handleName = (name) => {
    if (/^[a-zA-Z]*$/.test(name.target.value)) {
      setName(name.target.value);
    }
  };
  const handleSurname = (surname) => {
    setSurname(surname.target.value);
  };
  const handlePassword = async (password) => {
    setPassword(password.target.value);
  };
  const handleConfirmPassword = async (confirmPassword) => {
    setConfirmPassword(confirmPassword.target.value);
  };
  const handleBusinessName = async (businessName) => {
    setBusinessName(businessName.target.value);
  };
  const handleVatNumber = async (vatNumber) => {
    setVatNumber(vatNumber.target.value);
  };
  const handleFiscalCode = async (fiscalCode) => {
    setFiscalCode(fiscalCode.target.value);
  };
  const handleAddress = async (address) => {
    setAddress(address.target.value);
  };
  const handlePhone = async (phone) => {
    setPhone(phone.target.value);
  };
  const handleCity = async (city) => {
    setCity(city.target.value);
  };
  const handleZipCode = async (zipCode) => {
    setZipCode(zipCode.target.value);
  };
  const handleProvince = async (province) => {
    setProvince(province.target.value);
  };
  const handleNation = async (nation) => {
    setNation(nation.target.value);
  };
  const handlePrivacy = async (privacy) => {
    setPrivacy(privacy.target.checked);
  };
  const handleTermsAndConditions = async (termsAndConditions) => {
    setTermsAndConditions(termsAndConditions.target.checked);
  };
  const handleIban = async (iban) => {
    setIban(iban.target.value);
  };

  return (
    <section className="h-screen">
      <div className="flex flex-wrap justify-center h-screen">
        <div className="lg:basis-1/2">
          <RegistrationForm
            promotionCode={promotionCode}
            isLoading={isLoading}
            handleSignUp={handleSignUp}
            handlePassword={handlePassword}
            handleEmail={handleEmail}
            handleName={handleName}
            handleSurname={handleSurname}
            handleConfirmPassword={handleConfirmPassword}
            handleBusinessName={handleBusinessName}
            handleVatNumber={handleVatNumber}
            handleFiscalCode={handleFiscalCode}
            handleAddress={handleAddress}
            handlePhone={handlePhone}
            handleCity={handleCity}
            handleZipCode={handleZipCode}
            handleProvince={handleProvince}
            handleNation={handleNation}
            handlePrivacy={handlePrivacy}
            handleIban={handleIban}
            handleTermsAndConditions={handleTermsAndConditions}
          />
        </div>
        <div
          style={{
            backgroundImage:
              "url('https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80')",
          }}
          className="hidden lg:block lg:basis-1/2"
        ></div>
      </div>
      <ErrorBanner
        message={errorMessage}
        show={showErrorMessage}
        setShow={setShowErrorMessage}
      />
    </section>
  );
};
