import React, { useState } from "react";
import PopupCheckoutSubscription from "./PopupCheckoutSubscription";
import { CheckIcon } from "@heroicons/react/solid";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetAllSubscriptionsQuery,
  usePaySubscriptionMutation,
  useUpgradeSubscriptionMutation,
} from "../../services/stripeApi";
import PopupConfirmation from "../PopupConfirmation";
import { setSubscription } from "../../features/auth/authSlice";
import { stripeBillingPortal } from "../../config/baseUrl";

// const tiers = [
//   {
//     name: "Trial",
//     id: "4", //"1",
//     href: "#",
//     price: "€0,99",
//     description:
//       "Crediamo nella qualità del nostro servizio e per questo ti offriamo un mese di prova per un caffè.",
//     features: [
//       { text: "Accesso al catalogo winner", available: true },
//       {
//         text: "Checklist/Miniguida al Pagamento in Contrassegno",
//         available: true,
//       },
//       //   { text: "Supporto Whatsapp", available: false },
//       //   { text: "Integrazione Shopify", available: false },
//     ],
//     mostPopular: false,
//     subscriptionPrice: "price_1OgwZRG1z4xmFCxdNZ4mfUHz", // "price_1OcEwaG1z4xmFCxdWsMsJdri",
//   },
//   {
//     name: "Start-up",
//     id: "5", //"2",
//     href: "#",
//     price: "€49.90",
//     description:
//       "La giusta soluzione per avere più controllo del tuo e-commerce e una gestione comoda dei tuoi ordini",
//     features: [
//       {
//         text: "Piattaforma Integrata per Marketplace e Shopify",
//         available: true,
//       },
//       { text: "Assistenza via mail 24/7", available: true },
//       { text: "Spedizioni Illimitate", available: true },
//       {
//         text: "Consulenza Mensile di 30 min con un Team di Esperti",
//         available: true,
//       },
//       { text: "Richiesta Prodotti Non in Catalogo 48/72 ore", available: true },
//     ],
//     mostPopular: true,
//     subscriptionPrice: "price_1OgwYmG1z4xmFCxdPgdtnm90", // "price_1OcExfG1z4xmFCxdsdBRd3zX",
//   },
//   {
//     name: "Business",
//     id: "6", //"3",
//     href: "#",
//     price: "€249.90",
//     description:
//       "Pensiamo a tutto noi. La soluzione perfetta per dare un boost eccezionale alla tua attività online.",
//     features: [
//       { text: "Tutto lo Start-up", available: true },
//       { text: "Assistenza Prioritaria su WhatsApp 24/5", available: true },
//       { text: "Sconto del 5% sulle Spedizioni", available: true },
//       { text: "Richiesta Prodotti Non in Catalogo 12 ore", available: true },
//       { text: "Esclusiva su 1 Prodotto in Catalogo", available: true },
//       //   { text: "15€ credito per campioni omaggio", available: true },
//     ],
//     mostPopular: false,
//     subscriptionPrice: "price_1Ocb2bG1z4xmFCxdSVpoRp7a",
//   },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const SubscriptionsPlan = ({ paymentMethodId }) => {
  const [openCheckoutSubscription, setOpenCheckoutSubscription] =
    useState(false);
  const [subscriptionPrice, setSubscriptionPrice] = useState(null);
  //const [frequency, setFrequency] = useState(frequencies[0]);
  const [paySubscription] = usePaySubscriptionMutation();
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const from = "/register";
  const [subscriptionToUpgrade, setSubscriptionToUpgrade] = useState(null);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [upgradeSubscription] = useUpgradeSubscriptionMutation();
  const dispatch = useDispatch();

  const {
    data: tiers,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllSubscriptionsQuery();

  const handleSubscriptionButtonClick = async (tier) => {
    if (auth.subscriptionPrice == null) {
      if (auth.accessToken == "") {
        navigate(from);
        return;
      }
      const res = await paySubscription({
        paymentMethod: "", //result.paymentMethod.id,
        subPrice: tier.code,
      });
      window.location.href = res.data;
    } else {
      window.location.href = stripeBillingPortal;
    }
  };

  const handleConfirmUpgradeSubscription = async () => {
    await upgradeSubscription({
      subscriptionPrice: subscriptionToUpgrade.subscriptionPrice,
    })
      .then((response) => {
        const subscription = response.data;
        dispatch(setSubscription({ subscriptionPrice: subscription.plan.id }));
      })
      .finally(() => {
        setSubscriptionToUpgrade(null);
        setShowUpgradePopup(false);
      });
  };

  return (
    <>
      <div className="bg-white " id="home-subscriptions">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          {/* <div className="mx-auto max-w-4xl text-center">
            <h2 className="text-base font-semibold leading-7 text-green-600">
              Abbonati
            </h2>
            <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Scegli il piano giusto per te
            </p>
          </div> */}

          <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {isSuccess &&
              tiers.map((tier) => (
                <div
                  key={tier.id}
                  className={classNames(
                    tier.mostPopular
                      ? "ring-2 ring-green-600"
                      : "ring-1 ring-gray-200",
                    "rounded-3xl p-8 xl:p-10"
                  )}
                >
                  <div className="flex items-center justify-between gap-x-4">
                    <h3
                      // id={tier.id}
                      className={classNames(
                        tier.mostPopular ? "text-green-600" : "text-gray-900",
                        "text-2xl font-semibold leading-8"
                      )}
                    >
                      {tier.title}
                    </h3>
                    {tier.mostPopular ? (
                      <p className="rounded-full bg-green-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-green-600">
                        Più Popolare
                      </p>
                    ) : null}
                  </div>
                  {/* <p className="mt-4 text-sm leading-6 text-gray-600">
                  {tier.description}
                </p> */}
                  <p className="mt-6 flex items-baseline gap-x-1">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      {tier.price}
                    </span>
                    {/* <span className="text-sm font-semibold leading-6 text-gray-600">
                    {frequency.priceSuffix}
                  </span> */}
                  </p>
                  {auth?.subscriptionPrice == tier.code ? (
                    <a
                      aria-describedby={tier.id}
                      className={classNames(
                        tier.mostPopular
                          ? "bg-green-600 text-white shadow-sm hover:bg-green-500"
                          : "text-green-600 ring-1 ring-inset ring-green-200 hover:ring-green-300",
                        "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                      )}
                    >
                      ABBONAMENTO ATTIVO
                    </a>
                  ) : (
                    <a
                      //href={tier.href}
                      onClick={() => handleSubscriptionButtonClick(tier)}
                      aria-describedby={tier.id}
                      className={classNames(
                        tier.mostPopular
                          ? "bg-green-600 text-white shadow-sm hover:bg-green-500"
                          : "text-green-600 ring-1 ring-inset ring-green-200 hover:ring-green-300",
                        "mt-6 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                      )}
                    >
                      Acquista ora
                    </a>
                  )}

                  <ul
                    role="list"
                    className="mt-8 space-y-3 text-sm leading-6 text-gray-600 xl:mt-10"
                  >
                    {tier.subscriptionFeatures.map((feature, index) => (
                      <li
                        key={`${feature.text}${tier.name}`}
                        className="flex gap-x-3"
                      >
                        <CheckIcon
                          className={`h-6 w-5 flex-none ${
                            feature.available == true
                              ? "text-green-600"
                              : "text-gray-400"
                          }`}
                          aria-hidden="true"
                        />
                        {feature.text}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
        </div>
      </div>
      <PopupCheckoutSubscription
        subscriptionPrice={subscriptionPrice}
        open={openCheckoutSubscription}
        setOpen={setOpenCheckoutSubscription}
      />
      <PopupConfirmation
        message={"Confermi di voler modificare il tuo abbonamento."}
        show={showUpgradePopup}
        setShow={setShowUpgradePopup}
        handleConfirmClick={handleConfirmUpgradeSubscription}
      />
    </>
  );
};

export default SubscriptionsPlan;
