import React from "react";

function HeaderColTable({ children, colSpan, className }) {
  return (
    <th
      scope="col"
      colSpan={colSpan ?? 1}
      className={`px-3 py-3.5 text-sm font-semibold text-gray-900 sm:pl-3 ${
        className ?? "text-start"
      }`}
    >
      {children}
    </th>
  );
}

export default HeaderColTable;
