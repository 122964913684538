import React from "react";
import CustomerProductBox from "./CustomerProductBox";

function CustomerProductList({ products }) {
  return (
    <main className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-4 lg:grid-cols-3">
      {products?.map((p, index) => (
        <CustomerProductBox product={p} key={index} />
      ))}
    </main>
  );
}

export default CustomerProductList;
